.home_navbar {
  // position: absolute;
  position: sticky;
  z-index: 2;
  margin-top: 20px;
  top: 0;
  // width: calc(100vw - 224px);
  height: 80px;
  border-radius: 8px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.42);
  background-image: linear-gradient(87deg, #25378d 0%, #1a71a5 100%);
  transition: cubic-bezier(0.79, 0.01, 0.25, 0.99);
  // padding-right: 5px;
  @media only screen and (max-width: 1024px) {
    // height: 70px;
    // width: calc(100vw - 70px);
    // left: -80px;
    padding-left: 15px;
  }

  // @media only screen and (max-width: 375px) {
  //   margin-top: 0;
  //   width: 375px;
  //   border-radius: unset;
  // }

  // display: flex;
  // justify-content: space-between;
  // align-items: center;

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .home_nav_icon {
    margin: 0 10px;
  }

  .home_nav_icon:last-child {
    margin-right: 27px;
    @media only screen and (max-width: 1000px) {
      margin-right: 5px;
    }
  }
  .home_nav_icon:nth-child(2) {
    @media only screen and (max-width: 375px) {
      display: none;
    }
  }

  .home_navbar_link_button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: #ffffff;
    margin: 0 10px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.29;
    letter-spacing: 0.7px;
    border-bottom: 4px solid transparent;
    @media only screen and (max-width: 1000px) {
      font-size: 10px;
      margin: 0 2px;
    }
  }

  .home_link_container {
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 1024px) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
    @media only screen and (max-width: 500px) {
      display: none;
    }

    @media only screen and (min-width: 1025px) {
      padding-left: 10px !important;
    }
  }
  .home_link_container_full {
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 500px) {
      display: none;
    }
    @media only screen and (min-width: 1025px) {
      padding-left: 10px !important;
    }
  }

  .navbar_icons_container {
    grid-column-start: 3;
    grid-column-end: 4;
    justify-self: end;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 1200px) {
      grid-column-end: 4;
    }
    @media only screen and (max-width: 1200px) {
      grid-column-start: 3;
    }

    // @media only screen and (max-width: 900px) {
    //   grid-column-end: 19;
    // }

    // @media only screen and (max-width: 500px) {
    //   //display: none;
    // }
    button:nth-child(2) {
      @media only screen and (max-width: 375px) {
        display: none;
        justify-content: right;
      }
    }
  }
  .navbar_icons_container_full {
    grid-column-start: 3;
    grid-column-end: 4;
    justify-self: end;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 500px) {
      display: none;
    }
    button:nth-child(2) {
      @media only screen and (max-width: 375px) {
        display: none;
        justify-content: right;
      }
    }
  }

  .tab_underline {
    width: 50px;
    height: 4px;
    background-color: #ec6338;
  }

  .home_navbar_link_button:nth-child(1) {
    margin-left: 27px;
    @media only screen and (max-width: 1000px) {
      margin-left: 5px;
    }
    @media only screen and (min-width: 1025px) {
      display: none;
    }
  }

  .product_search_icon {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: #ffffff;
    margin: 0;
    position: relative;
    width: 50px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 1200px) {
      width: 42px;
    }

    .notification_red_dot {
      width: 20px;
      height: 20px;
      border-radius: 9.9px;
      background-color: #d60909;
      position: absolute;
      right: left;
      left: 22px;
      top: -11px;
      // width: 7px;
      // height: 14px;
      font-family: "Open Sans", sans-serif;
      font-size: 10px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      text-align: center;
      color: #ffffff;
      padding-top: 2px;
      display: none;
    }
  }
}

.home_navbar_continer {
  position: relative;
  // margin: 0 auto;
  grid-column-start: 2;
  grid-column-end: 18;
  grid-row-start: 1;
  grid-row-end: 2;
  // width: calc(100vw - 240px);
}

.home_navbar_continer_full {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 19;
  grid-row-start: 1;
  grid-row-end: 2;
  margin: 0 20px;
}

.home_navbar_fixed {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  // padding-right: 35px;
  position: fixed;
  left: 0;
  right: 0;
  margin: 0;
  border-radius: 0 !important;
  width: 100%;
  z-index: 3;
  padding: 0 20px;
  //display: grid;
  // padding: 0 110px;

  // padding-left: 110px;
  // padding-right: 98px;
  transition: cubic-bezier(0.79, 0.01, 0.25, 0.99);
  // @media only screen and (max-width: 1024px) {
  //   // padding-left: 65px;
  //   // padding-right: 30px;
  // }
}
.home_navbar_fixed_padding_full {
  padding-left: 20px;
  padding-right: 20px;
}

.home_logo_icon {
  @media only screen and (max-width: 1000px) {
    width: 150px;
  }
  @media only screen and (max-width: 500px) {
    width: 150px;
    margin: 0 auto;
  }
}

.logo_home_button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  grid-column-start: 2;
  grid-column-end: 3;
  // margin-right: 75px;
  @media only screen and (max-width: 1200px) {
    grid-column-start: 2;
  }
  @media only screen and (max-width: 1024px) {
    grid-column-start: 2;
    grid-column-end: 3;
  }
}

.logged_in_name {
  border: 2px solid #f18846;
  border-radius: 50%;
  color: #f18846;
  // padding: 6px;
  font-family: "Open sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.51px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
// .mallAndShopNav {
//   @media only screen and (max-width: 1024px) {
//     left: 50px !important;
//   }
// }
.nav-close {
  position: absolute !important;
  font-size: 21px !important;
  left: 30px !important;
  cursor: pointer;
  color: #ffffff;
  top: 25px;
}

.home_link_container_tab {
  padding-left: 15px;
}

.underline_bottom{
  border-bottom: 4px solid #ec6338 !important;
}