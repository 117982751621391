.product-card {
  position: relative;
  // height: 100%;
  min-height: 480px;
  // min-height: 500px;
  @media (min-width: 1300px) and (max-width: 1400px) {
    min-height: 500px;
  }
  @media (min-width: 1400px) and (max-width: 1600px) {
    min-height: 460px;
  }
  @media only screen and (max-width: 1040px) {
    // min-height: 420px;
    min-height: 55vw;
  }
  @media (min-width: 1030px) and (max-width: 1040px) {
    min-height: 40vw;
  }
  @media only screen and (max-width: 800px) {
    min-height: 440px;
  }
  @media only screen and (max-width: 530px) {
    min-height: 600px;
  }

  @media only screen and (max-width: 700px) {
    min-height: 110vw;
  }
  border-radius: 8px;
  box-shadow: 0 0 25px 0 rgba(139, 192, 221, 0.5);
  background-color: #fff;
  margin-bottom: 21px;
  position: relative;
  width: 100%;
  padding-top: 75%;
}
.product-card-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.product-card .store-image {
  background: url("../../assets/images/shoping-centre.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  // height: 300px;
  padding-top: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.product-card .name-description {
  width: 100%;
  max-height: 64px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: 0.67px;
  color: #333333;
  padding: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  // margin-bottom: 16px;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.address-description img {
  align-self: flex-start;
  // margin-top: -5px;
  margin-right: 5px;
}
.product-card .address-description {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.09px;
  color: #616161;
  padding: 0 15px 15px 15px;
  display: flex;
  align-items: center;
}
.promo_text_card {
  width: auto;
  height: 16px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.39px;
  color: #dd0202;
  margin: 0 10px;
  margin-bottom: 20px;
  text-align: center;
}

.old_new_price_container_card {
  margin: 0 10px;
  text-align: center;

  .old_price {
    width: max-content;
    height: 22px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.39px;
    color: #7a7a7a;
    text-decoration: line-through;
    margin-right: 20px;
  }

  .new_price {
    width: max-content;
    height: 24px;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.57px;
    color: #333333;
  }
}
