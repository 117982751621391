.main_container {
  // display: flex;
  // flex-direction: column;
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  grid-template-rows: 150px auto auto;
  // display: grid;
  // grid-template-columns: repeat(10, minmax(100px, 1fr));
  // grid-template-rows: 200px auto 400px;
}

// .common_navbar {
//   grid-column-start: 2;
//   grid-column-end: 18;
//   grid-row-start: 1;
//   grid-row-end: 2;
// }

.page_content {
  // flex: 1 0 auto;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 19;
}
.main_footer {
  // flex-shrink: 0;
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 19;
  align-self: end;
}
