.popup-content {
    height: fit-content;
    max-height: calc(100vh - 52px);
    overflow-y: auto;
    box-shadow: 0 0 23px 10px rgba(0, 0, 0, 0);
    -ms-overflow-style: none; // IE and edge compatibility
    &::-webkit-scrollbar { // hide scrollbar but keep functionality
        display: none;
      }
    width: unset!important;
    @media screen and (max-width: "576px") {
        max-width: 90%;
    }
}

.popup {
    width: 720px;
    @media screen and (max-width: "768px") {
        width: 90vw;
    }
    .cta {
        @media screen and (max-width: "576px") {
            width: 150px;
        }
    }
}

.popup-header {
    margin-top: 30px;
    margin-bottom: 28px;
    font-size: 18px;
    letter-spacing: 0.77px;
    color: #333333;
    width: 100%;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
}
.popup .content {
    width: 100%;
    max-width: 525px;
    margin: 0 auto;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    line-height: 1.39;
    letter-spacing: 0.77px;
    text-align: center;
    color: #333333;
    margin-bottom: 28px;
}
.popup .actions {
    width: 100%;
    padding: 10px 5px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.popup .close {
    width: 16px;
    height: 16px;
    right: 0px;
    top: 0px;
    position: absolute;
    padding-right: 20px;
    cursor: pointer;
    color: #212528;
    opacity: unset;
    font-size: 28px;
    margin-right: 24px;
    margin-top: 24px;
}