.slick-list {
  display: flex;
  margin: 0 auto;
  max-width: calc(100vw - 50px);
  //@media screen and (min-width: 992px) {
  //  max-width: calc(100vw - 150px);
  //}
  height: 100%;
}
//
.slick-slider {
  height: 100%;
}

.slick-track {
  display: inline-flex;
  margin: auto 0;
}

.slick-slide {
  outline: none !important;
}
.carousel-photo {
  outline: none !important;
  //width: 100% !important;
  display: flex;
  cursor: pointer;
  //height: 75vh !important;
  @media only screen and (max-width: 768px) {
    height: 50vh !important;
  }
}
.carousel-photo-modal {
  outline: none !important;
  height: 100vh;
  display: flex;
  // cursor: pointer;
  @media only screen and (max-width: 768px) {
    height: 50vh !important;
  }
}
.carousel-shop-image {
  padding: 10px;
  display: flex;
  height: 380px;
  @media only screen and (max-width: 1024px) {
    padding: 10px;
    height: 100%;
  }
  @media only screen and (max-width: 768px) {
    height: 50vh;
    padding: 20px;
    justify-content: center;
    align-items: center;
    //margin-right: 15px;
  }
}
.shop-image-slick-arrows {
  margin: 5px;
  cursor: pointer;
}
.slick-arrow,
.slick-next {
  display: none !important;
}
.shop-image-open {
  // width: 30% !important;
  width: auto !important;
  max-width: 100% !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.thumbs-wrapper {
  .thumbs {
    .thumb {
      img {
        width: 100% !important;
      }
    }
  }
}

.carousel {
  .carousel-slider {
    height: 75vh !important;
  }
}

.right_arrow {
  position: absolute;
  right: 50px;
  z-index: 2;
}
.left_arrow {
  position: absolute;
  left: 50px;
  z-index: 2;
}
.gallary_large_modal {
  .carousel .control-arrow:before,
  .carousel.carousel-slider .control-arrow:before {
    border-top: 30px solid transparent !important;
    border-bottom: 30px solid transparent !important;
    margin: 0 50px !important;
  }
  .carousel .control-prev.control-arrow:before {
    border-right: 30px solid #fff;
  }
  .carousel .control-next.control-arrow:before {
    border-left: 30px solid #fff;
  }

  .carousel.carousel-slider .control-arrow {
    height: max-content;
    top: 50% !important;
  }
}
