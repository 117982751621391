* {
  margin: 0px;
  padding: 0px;
}
html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
}

body.fontLoaded {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#root {
  background-color: #fafafa;
  min-height: 100%;
  min-width: 100%;
}

p,
label {
  font-family: Georgia, Times, "Times New Roman", serif;
  line-height: 1.5em;
}
.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.6);
}

// fonts
@font-face {
  font-family: "Montserrat-Bold";
  src: url("../src/fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("../src/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../src/fonts/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "Nexa-Bold";
  src: url("../src/fonts/Nexa Bold.otf");
}
