.verified_user_text {
  margin-top: 20px;
  width: 100%;
  height: auto;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.18px;
  text-align: center;
  color: #333333;
}
