.new-landing-gif {
    padding-top: 125px;
    @media only screen and (max-width: 767px) {
        padding-top: 95px;
    }
    color: #333333;
    background-color: #cceaf2;
    .title {
        font-family: Montserrat-Bold;
        font-size: 50px;
        color: #285780;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 40px;
        text-transform: uppercase;
        line-height: 1.2;
        max-width: 90%;
        margin: auto;
        @media only screen and (max-width: 1024px) {
            font-size: 30px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 26px;
            padding-top: unset;
        }
    }
    img {
        width: 100%;
    }
}