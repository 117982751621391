.search_box_main_container {
  width: 100%;
  height: 250px;
  border-radius: 8px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.42);
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 45px 25px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  .city_search_container {
    flex: 0 0 30%;
    width: 100% !important;
    // width: 20vw !important;
    height: 45px !important;
    input {
      width: 326px !important;
      height: 45px !important;
      border-radius: 8px !important;
      border: solid 1px #d5d5d5 !important;
      background-color: #ffffff !important;
    }
    .ui.search.dropdown .menu {
      max-height: 120px;
    }
    // i {
    //   display: none;
    // }
    .ui.selection.active.dropdown .menu {
      border-color: #d5d5d5 !important;
    }
    .ui.selection.active.dropdown {
      border-color: #d5d5d5 !important;
    }
  }
}
.city_search_container i {
  top: 15px !important;
}
.search_box_main_container_padding {
  // padding: 45px 88px 45px 106px;
  padding: 45px 80px 45px 100px;
  border-radius: 0;
}
.search_item {
  display: inline-block;
  flex: 0 0 40%;
  @media only screen and (max-width: 768px) {
    flex: 0 0 30%;
  }
  .react-autosuggest__suggestions-list {
    display: none;
    background-color: #ffffff;
    padding: 0 20px;
    li {
      list-style: none;
      //   padding: 1px 0;
      :hover {
        cursor: pointer;
      }
      :nth-child(1) {
        padding-top: 8px;
      }
      :last-child {
        padding-bottom: 8px;
      }
    }
  }
  > div {
    width: 100%;
  }
  .search_input_home {
    input {
      border-radius: 8px !important;
    }
    .ui.input.focus > input,
    .ui.input > input:focus {
      border-color: #ffffff;
    }
  }
  input {
    width: 100% !important;
    // width: 50vw;
    height: 50px;
    border-radius: 8px;
    border: solid 1px #d5d5d5;
    background-color: #ffffff;
    outline: none;
    padding: 20px;
    cursor: initial;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .react-autosuggest__suggestions-container--open {
    width: 760px;
    height: 45px;
    // border: solid 1px #d5d5d5;
    border: none;
    background-color: #ffffff;
  }
}
.recent_search_heading {
  position: absolute;
  top: 50%;
  left: 27px;
  width: 288px;
  height: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
}
.recent_search_heading_padding {
  position: absolute;
  top: 50%;
  left: 107px;
  width: 288px;
  height: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
}
.recent_items_container {
  display: flex;
  position: absolute;
  top: 70%;
  left: 86px;
  .recent_item {
    position: relative;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    // width: 119px;
    height: 35px;
    border-radius: 16px;
    border: solid 1px #dadada;
    padding: 4px 56px 4px 18px;
    color: #9f9e9e;
    margin: 0 20px;
  }
}
.recent_items_container_tab_half {
  left: 5px;
  .recent_item {
    @media only screen and (max-width: 1000px) {
      padding: 4px 30px 4px 18px;
      margin: 0 3px;
    }
  }
}
.close_search_icon_image {
  position: absolute;
  right: 5px;
}
.close_search_icon_image_scrolled {
  position: absolute;
  right: 50px;
}
.product_searh_close_icon {
  position: absolute;
  right: 40px;
  top: 15px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: #ffffff;
  margin: 0;
}
.homeSearchButton {
  flex: 0 0 210px;
  width: 100% !important;
  // width: 13vw !important;
  height: 50px !important;
  @media only screen and (max-width: 1024px) {
    // width: 100px !important;
    max-width: 120px !important;
    margin-left: 20px !important;
  }
  @media only screen and (max-width: 800px) {
    font-size: 14px !important;
  }
  &:disabled {
    background: #818181 none !important;
  }
}
.tab_screen_half {
  input {
    @media only screen and (max-width: 768px) {
      width: 25vw !important;
    }
  }
}
.searchLoader {
  left: 6px !important;
  top: -4px !important;
}

.cities_header_dropdown {
  margin: 0 2vw;
  width: 100%;
}
