.signup_modal {
  form.ui.form input[type='text'] {
    border-radius: 8px;
    height: 50px;
    margin-bottom: 15px;
    margin-right: 10px;
    margin-left: 10px;
    border: solid 1px #d5d5d5;
    padding-left: 40px;
  }

  form.ui.form input[type='password'] {
    border-radius: 8px;
    height: 50px;
    margin-bottom: 15px;
    margin-right: 10px;
    margin-left: 10px;
    padding-right: 50px;
    border: solid 1px #d5d5d5;
    padding-left: 40px;
  }
  form.ui.form input[type='email'] {
    border-radius: 8px;
    height: 50px;
    margin-bottom: 15px;
    margin-right: 10px;
    margin-left: 10px;
    border: solid 1px #d5d5d5;
    padding-left: 40px;
  }

  form.ui.form input[type='text']::-webkit-input-placeholder {
    height: 19px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.44px;
    color: #9e9e9e;
    padding-left: 0px;
  }
}

.register_button {
  display: block !important;
  margin: 0 auto !important;
  width: 208px;
  height: 44px;
  border-radius: 8px !important;
  color: #ffffff !important;
  background: linear-gradient(
    to top,
    #ab2814 1%,
    #ec6338 15%,
    #f39149
  ) !important;
}

.register_button:hover {
  background: linear-gradient(
    to bottom,
    #ab2814 1%,
    #ec6338 15%,
    #f39149
  ) !important;
}

.password_reset_button {
  border: none;
  font-weight: bold;
  color: black;
  text-decoration: underline;
  background-color: #ffffff;
  outline: none;
}
.password_reset_button:hover {
  cursor: pointer;
}

.register_button {
  position: relative;
  .login_loader {
    position: absolute !important;
    top: 5px !important;
    left: 45% !important;
  }
}
.password_reset_button_login {
  margin-left: 0 !important;
}
