.thank-you {
    padding-top: 125px;
    @media only screen and (max-width: 767px) {
        padding-top: 95px;
    }
    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 70vh;
    }
    &-title {
        font-family: Montserrat-Bold;
        font-size: 40px;
        letter-spacing: 0.37px;
        text-align: center;
        color: #285780;
        margin-bottom: 14px;
    }
    &-subtitle {
        font-family: Montserrat-Regular;
        font-size: 20px;
        line-height: 2.05;
        letter-spacing: 0.18px;
        text-align: center;
        color: #285780;
    }
}