.new-landing-navbar {
    max-width: 1440px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    position: fixed;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    z-index: 40;
    background-color: #ffffff;
    padding: 25px 35px;
    @media only screen and (max-width: 767px) {
        padding: 20px 20px;
        align-items: unset;
    }
    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: 1024px) {
            img {
                width: 180px;
            }
        }
        @media only screen and (max-width: 767px) {
            img {
                width: 140px;
            }
        }
    }
    .link-container {
        button {
            font-family: Montserrat-Regular;
            font-size: 18px;
            @media only screen and (max-width: 767px) {
                font-size: 16px;    
            }
            color: #8a8b8a;
            background: none;
            border: none;
            cursor: pointer;
            outline: inherit;
            padding-right: 15px;
            padding-left: 15px;
        }
        b {
            font-family: Montserrat-Bold;
        }
    }
    .button-container {
        button {
            width: 139px;
            @media only screen and (max-width: 1024px) {
                width: 120px;
            }
            @media only screen and (max-width: 767px) {
                display: none;
            }
            height: 36px;
            border: solid 1.2px #eaa936;
            font-family: Montserrat-SemiBold;
            font-size: 18px;
            color: #285780;
            border-radius: 8px;
            background-color: #ffffff;
            cursor: pointer;
            outline: none;
        }
        .signup-button {
            margin-right: 20px;
        }
        .login-button {
            background-color: #eaa936;
        }
    }
    .contact-button {
        width: 215px;
        @media only screen and (max-width: 767px) {
            width: 140px;
            height: 40px;
        }
        height: 50px;
        border: none;
        border-radius: 4px;
        background-color: #ff1e49;
        font-family: Montserrat-Bold;
        font-size: 18px;
        letter-spacing: 0.17px;
        text-align: center;
        color: #ffd60a;
        text-transform: uppercase;
        outline: none;
        cursor: pointer;
    }
}