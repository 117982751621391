.store_registration_form_container {
  // background-image: url('../../assets/images/StoreRegistraionBackg.png');
  // height: 3900px;
  background-position: left 0px top 0px;
  background-size: cover;
  padding-bottom: 50px;
  .landing_login_button-container {
    @media screen and (max-width: "768px") {
      margin-top: 35px;
    } 
  }
  .landing_login_button {
    position: relative;
    width: 374px;
    height: 44px;
    margin: 46px 175px;
    @media screen and (max-width: "768px") {
      width: 100%;
      height: unset;
      margin: unset;
      margin: auto;
    }
  }
}

.store_register_accordian {
  //   padding: 31px;
  width: 746px;
  @media screen and (max-width: 768px) {
    width: unset;  
    margin: 25px auto;
    padding: 10px;
  }
  position: relative;
  margin: 50px auto;
  //   height: 400px;
  .title {
    position: relative;
    padding-left: 30px !important;
    padding-top: 15px !important;
    width: 746px;
    @media screen and (max-width: 768px) {
      width: unset;  
      padding-left: 20px !important;
    }
    height: 77px;
    border-radius: 8px;
    box-shadow: 0 0 45px 0 rgba(7, 12, 69, 0.19);
    background-color: #ffffff;
  }
}
.input-row {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.sub-domain-field {
  display: flex;
  flex-direction: row;
}

.input_error_message {
  position: absolute;
  width: 100%;
  top: 100px;
  left: 14px;
  color: red;
  // color: rgb(207, 163, 162);
  font-family: 'Open Sans', sans-serif;
}

.domain-input-container {
  flex: 1 1 auto;
  input {
    border-radius: unset!important;
  }
}
.domain-btn-box {
  display: flex;
  flex: 0 0 200px;
  @media screen and (max-width: 768px) {
    flex: 0 0 80px;
  }
  height: 50px;
  border-radius: 8px;
  border: solid 1px #d5d5d5;
  background-color: #f4f4f4;
  .domain-btn {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: #6f6f6f;
    letter-spacing: 0.44px;
    text-align: center;
    margin: auto;
    font-size: 14px;
    border-bottom-left-radius: unset!important;
    border-top-left-radius: unset!important;
    width: 100%;
  }
}
.store_register_accordian_icon {
  position: absolute;
  right: 23px;
  @media screen and (max-width: 768px) {
    right: 5px;
  }
  top: 30px;
  font-size: 30px !important;
}

.store_register_accordian_heading {
  width: 92px;
  height: 47px;
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: 0.23px;
  color: #333333;
}
.account_section {
  border-radius: 8px;
  box-shadow: 0 0 45px 0 rgba(7, 12, 69, 0.19);
  background-color: #ffffff;
  input[type='text'],
  input[type='email'],
  input[type='password'] {
    border-radius: 8px !important;
    border: solid 1px #d5d5d5 !important;
    background-color: #ffffff !important;
  }
}
.ui.input {
  width: 327px;
  height: 50px;
  @media screen and (max-width: 768px) {
    width: 100%;
    input {
      margin-right: unset!important;
      margin-left: unset!important;
    }
  }
}
.accordian_box_style {
  border-radius: 8px;
  box-shadow: 0 0 45px 0 rgba(7, 12, 69, 0.19);
  background-color: #ffffff;
}
.business_details_section {
  margin-top: 30px;
  input[type='text'] {
    border-radius: 8px !important;
    border: solid 1px #d5d5d5 !important;
    background-color: #ffffff !important;
  }
}
.business_type_heading {
  margin: 20px 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.44px;
  color: #333333;
}
.business_type {
  position: relative;
  label {
    width: 176px;
    height: 19px;
    font-family: 'Open Sans';
    font-size: 14px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.44px;
    color: #333333 !important;
  }
}
.biilind_address_section {
  margin-top: 30px;
  input[type='text'],
  input[type='number'] {
    border-radius: 8px !important;
    border: solid 1px #d5d5d5 !important;
    background-color: #ffffff !important;
    margin-right: unset!important;
    margin-left: unset!important;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.fee_details_section {
  // display: none;
  margin-top: 30px;
  .fee-subtitle {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.18px;
    color: #333333;
  }
  .fee_description {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.09px;
    color: #333333;
  }
  .payment_info {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.08px;
    color: #9a9a9a;
  }
}

.payment_terms_section {
  display: none;
  margin-top: 50px;
  .payment_terms_description {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.09px;
    color: #333333;
    margin-bottom: 20px;
    padding-left: 10px;
  }
  .amount_to_paid {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.29px;
    color: #333333;
  }
  .net_amount {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.29px;
    color: #333333;
    margin-top: 20px;
  }
  input[type='text'],
  input[type='number'] {
    border-radius: 8px !important;
    border: solid 1px #d5d5d5 !important;
    background-color: #ffffff !important;
  }
  .payment_paid {
    padding: 20px;
    width: 680px;
    height: 136px;
    margin-left: 11px;
    background-color: #faf9f9;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.privacy_points_section {
  margin-top: 30px;
  .checkbox_tick {
    margin-top: 17px !important;
    width: 18px !important;
    height: 18px !important;
  }
  .privacy_points {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    width: 657px;
    height: 57px;
    // font-family: OpenSans;
    // font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: 0.96px;
    color: #333333;
  }
  .legal_info {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: 0.3px;
    color: #3a3a3a;
    @media screen and (max-width: "768px") {
      margin-left: unset!important;
    }
  }
}
.store_registration_heading {
  font-family: 'Open Sans', sans-serif;
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.36px;
  text-align: center;
  color: #333333;
  padding: 120px 100px 30px 100px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    font-size: 28px;
    padding: 100px 10px 10px 10px;
  }
}
.store_register_accordian {
  .input_container {
    padding-bottom: 13px;
    position: relative;
    .react-datepicker-wrapper {
      width: 327px;
      @media screen and (max-width: 768px) {
        width: 100%;
        input {
            margin-right: unset!important;
            margin-left: unset!important;
        }
      }
    }
  }
  .input_error_message {
    position: absolute;
    width: 100%;
    top: 53px;
    left: 14px;
    color: red;
    // color: rgb(207, 163, 162);
    font-family: 'Open Sans', sans-serif;
  }
}
.plan_grid {
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  column-gap: 20px;
  row-gap: 20px;
  button {
    &:disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }
}
.plan_container {
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    button {
      margin-bottom: 20px;
    }
  }
}

.monthly_payment_info {
  width: 326px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  height: 150px;
  border-radius: 8px;
  border: solid 1px #d5d5d5 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  outline: none;
}
.monthly_payment_info:hover {
  cursor: pointer;
}

.duration-button {
  height: 87px;
}

.yearly_payment_info {
  position: relative;
  overflow: hidden;
  .module-title {
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: 0.18px;
    text-align: center;
    color: #333333;
  }
  .gradient {
    background: #CFE7fA;
    background: -webkit-linear-gradient(to left, #468aca 0%, #3b58e0 100%);
    background: -moz-linear-gradient(to left, #468aca 0%, #3b58e0 100%);
    background: linear-gradient(to left, #468aca 0%, #3b58e0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-bottom: 1px solid #3b58e0;
  }
  .module-subtitle {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.12px;
    color: #333333;
    margin-top: 4px;
  }
  .module-description {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: 0.12px;
    text-align: center;
    margin: 5px 0;
    color: #333333;
    &:hover {
      text-decoration: underline;
    }
  }
  .module-fee {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.12px;
    text-align: center;
    color: #333333;
    margin: 4px 0;
    .old_price {
      text-decoration: line-through;
      color: #929191;
    }
    .new_price {
      color: red!important;
    }
    b {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.14px;
      color: #333333;
    } 
    .iva {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px;
      font-style: italic;
      letter-spacing: 0.1px;
      color: #929191;
    }
  }
 
  .promo {
    position: absolute;
    top: 10px;
    left: -29px;
    width: 137px;
    height: 25px;
    transform: rotate(-45deg);
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.12px;
    text-align: center;
    color: #ffffff;
    background-color: red;
    padding-top: 4px;
    padding-right: 19px;
  }
  .yearly_color {
    background-image: linear-gradient(65deg,#3b58e0 0%,#6bb5fc 100%) !important;
    border: unset!important;
    span {
      color: #ffffff!important;
    }
    b {
      color: #ffffff!important;
    }
    div {
      color: #ffffff!important;
    }
  }
}
.total-info {
  padding: 19px 18px 19px 16px;
  background-color: #faf9f9;
  &-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  &-label {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.29px;
    color: #333333;
    text-transform: uppercase;
  }
  &-description {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.24px;
    color: #333333;
  }
  &-price {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.29px;
    color: #333333;
    white-space: nowrap;
  }
  hr {
    margin: 15px 0;
  }
}

.checkout-coupons {
  &-title {
      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0.7px;
      color: #000000;
      margin-top: 13px;
      margin-bottom: 13px;
  }
  &-input {
      display: flex;
      padding-bottom: 6px;
      form {
          display: flex;
          width: 100%;
      }
      input {
          height: 45px;
          border-radius: unset!important;
          border-top-left-radius: 8px!important;
          border-bottom-left-radius: 8px!important;
          border: 1px solid #d8d8d8!important;
          width: 100%!important;
          border-right: none!important;
          padding-left: 16px!important;
          &::placeholder {
              letter-spacing: 0.12px;
              font-size: 14px;
              color: #b6b6b6;
          }
          @media screen and (max-width: "768px") {
              padding-left: 8px;
              font-size: 12px;
              &::placeholder {
                  font-size: 12px;
                  color: #b6b6b6;
              }
          }
      }
      button {
          height: 45px!important;
          border-radius: unset!important;
          border-top-right-radius: 8px!important;
          border-bottom-right-radius: 8px!important;
          width: 175px!important;
          border: none!important;
          margin: unset!important;
          cursor: pointer;
          @media screen and (max-width: "768px") {
              width: 120px;
          }   
      }
  }
}

.operator_register_check {
  input {
    margin-top: 0px !important;
    margin-right: 10px !important;
    width: 18px !important;
    height: 18px !important;
  }
  @media screen and (max-width: "768px") {
    input {
      width: 10% !important;
      height: 16px !important;
    }
    label {
      width: 90%;
    }
  }
}
.checkbox-text {
  color: black !important;
  &:hover {
    text-decoration: underline;
  }
}
.input_error_buisnessType {
  position: absolute;
  top: 61px !important;
  left: 5px !important;
  color: red;
  font-family: 'Open Sans', sans-serif;
}
.buiness_category_container {
  position: relative;
  .input_error_buisnessCateogry {
    position: absolute;
    top: 58px !important;
    left: 23px !important;
    color: red;
    font-family: 'Open Sans', sans-serif;
  }
  .selection {
    width: 100%!important;
    @media screen and (max-width: "768px") {
      margin-left: unset!important;
      margin-right: unset!important;
    }
  }
}

.business_message {
  margin-left: 15px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  @media screen and (max-width: "768px") {
    margin-left: unset;
  }
}

.business_message_invoice {
  margin-left: 15px;
  @media screen and (max-width: "768px") {
    margin-left: unset;
  }
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bolder;
  color: red;
}

.input_container_city_dropdown {
  flex: 0 0 53% !important;
  padding-top: 24px !important;
  .selection {
    @media screen and (max-width: "768px") {
      width: 100%!important;
      margin-left: unset!important;
      margin-right: unset!important;
    }
  }
}
.opSignUpLoader {
  position: absolute;
  top: -9px !important;
  left: 50%;
}

.input_container_city_dropdown i {
  top: 15px !important;
}
.buiness_category_container i {
  top: 15px !important;
}
.calendar_image {
  position: absolute;
  pointer-events: none;
  // left: 275px;
  left: 85%;
  @media screen and (max-width: 768px) {
    left: 92%;
  }
  @media screen and (max-width: 576px) {
    left: 85%;
  }
  top: 31px;
  border-left: 1px solid #d8d8d8;
  padding-left: 15px;
}

.text-block {
  font-family: Montserrat-Regular;
  font-size: 28px;
  line-height: 1.4;
  @media only screen and (max-width: 1024px) {
    font-size: 26px;
    line-height: 1.2;
  }
  @media only screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 1.2;
  }
  color: #285780;
  text-align: center;
}
.yellow-button {
  width: 300px;
  height: 55px;
  background-color: #ea8b32;
  color: #ffffff;
  border-radius: 10px;
  font-family: Montserrat-Bold;
  font-size: 28px;
  cursor: pointer;
  border: none;
  outline: none;
  @media only screen and (max-width: 1024px) {
    height: 36px;
    width: 170px;
    font-family: Montserrat-SemiBold;
    font-size: 18px;
    border-radius: 8px;
    padding: 10px;
    margin-top: 30px;
  }

  @media only screen and (max-width: 767px) {
    width: 200px;
    letter-spacing: 1px;
    font-size: 18px;
  }
}
