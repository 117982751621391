.business-contact-us {
    padding: 40px 0;
    text-align: center;
    .title {
        font-family: Montserrat-Bold;
        line-height: 1.68;
        font-size: 22px;
        letter-spacing: 0.2px;
        color: #000000;
    }
    .subtitle {
        font-family: Montserrat-Medium;
        font-size: 19px;
        line-height: 1.68;
        letter-spacing: 0.17px;
        color: #000000;
    }
    &-form {
        max-width: 80%;
        @media only screen and (max-width: 767px) {
            max-width: 100%;
        }
        margin: auto;
        margin-top: 40px;
        input[type=text], input[type=number] {
            height: 50px;
            width: 100%;
            padding: 15px;
            border-radius: 4px;
            border: solid 1px #979797;
            background-color: unset;
            font-family: Montserrat-Regular;
            font-size: 17px;
            letter-spacing: 0.16px;
            outline: unset;
            margin-bottom: 5px;
            &::placeholder {
                color: #7d7d7d;
            }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        
        .grid  {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 20px;
            @media only screen and (max-width: 767px) {
                grid-template-columns: 1fr;
            }
            &-input {
                margin-bottom: 25px;
                text-align: initial;
            }
        }
        .message_error {
            color: red;
            font-family: Montserrat-Regular;
            font-size: 14px;
        }
        .contact-button {
            margin-top: 25px;
            &:disabled {
                background-color: #ccc;
                color: #fff;
            }
        }
    }
    .checkbox_tick {
        margin-top: unset;
    }
    .checkbox_label {
        margin-left: unset;
        font-family: Montserrat-Regular;
        font-size: 17px;
        letter-spacing: 0.16px;
        color: #000000;
    }
    .activity {
        margin-bottom: 30px;
        display: flex;
        @media only screen and (max-width: 767px) {
            display: unset;
        }
        align-items: center;
        flex-wrap: wrap;
        .text {
            font-family: Montserrat-Bold;
            font-size: 17px;
            letter-spacing: 0.16px;
            color: #000000;
            margin-right: 38px;
            @media only screen and (max-width: 767px) {
                margin-right: unset;
                margin-bottom: 10px;
            }
        }
        &-checkbox {
            margin-right: 30px;
            display: flex;
            align-items: center;
        }
    }
    .message-box {
        text-align: initial;
        margin-bottom: 30px;
        @media only screen and (max-width: 767px) {
            margin-top: 20px;
        }
        textarea {
            width: 100%;
            outline: none;
            padding: 15px;
            background-color: unset;
            font-family: Montserrat-Regular;
            font-size: 17px;
            letter-spacing: 0.16px;
            border-radius: 4px;
            border: solid 1px #979797;
            &::placeholder {
                color: #7d7d7d;
            }
        }
    }
    .accept-checkbox {
        margin-bottom: 20px;
        text-align: initial;
        &-content {
            display: flex;
            align-items: center;
            @media only screen and (max-width: 767px) {
                display: unset;
            }
        }
    }
}