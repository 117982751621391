.business-container {
    padding: 0 30px;
    @media only screen and (max-width: 767px) {
        padding: 0 20px;
    }
    .text-block {
        max-width: 95%;
        margin: auto;
        .subtext-block {
            margin-top: 15px;
            margin-bottom: 20px;
        }
    }
    .contact-button {
        width: 320px;
        height: 50px;
        border: none;
        border-radius: 4px;
        background-color: #ff1e49;
        font-family: Montserrat-Bold;
        font-size: 18px;
        letter-spacing: 0.17px;
        text-align: center;
        color: #ffd60a;
        text-transform: uppercase;
        outline: none;
        cursor: pointer;
    }
    .separator {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .dotted-gradient {
        background-image: linear-gradient(to right, #979797 40%, rgba(255, 255, 255, 0) 20%);
        background-size: 60px 3px;
        background-repeat: repeat-x;
        width: 50%;
    }
}