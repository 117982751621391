.map_main_container {
  display: flex;
  justify-content: space-between;
}
.leaflet-container {
  width: 100%;
  // height: 100%;
  height: calc(100vh - 80px);
  z-index: 0;
  //   width: 100vw;
  // height: max-content;
}
.mapview_store_card {
  // width: 685px;
  width: 100%;
  // height: 155px;
  height: 200px;
  border-radius: 8px;
  box-shadow: 0 0 25px 0 rgba(139, 192, 221, 0.5);
  background-color: #ffffff;
  display: flex;
  position: relative;
  margin-top: 20px;
  @media only screen and (max-width: "1024px") {
    display: none;
  }
}
.mapview_store_card:hover {
  cursor: pointer;
}
.mapview_store_card_content_heading {
  // width: 341px;
  width: 100%;
  // height: 58px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.67px;
  color: #333333;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 27px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-right: 50px;
  // @media only screen and (max-width: "768px") {
  //   padding-right: 50px;
  // }
}
.mapview_store_card_content_address {
  display: flex;
  // align-items: center;
  img {
    align-self: flex-start;
  }
}
.mapview_store_card_content_address_text {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.09px;
  color: #616161;
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.mapview_store_card_faviourite {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 34px;
  height: 34px;
  border-radius: 8px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  // padding: 6px 16px 10px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mapview_store_card_content {
  padding: 20px 0;
  margin-left: 20px;
  // @media only screen and (min-width: "1300px") {
  //   width: 23vw;
  // }
  // @media (min-width: "1025px") and (max-width: "1300px") {
  //   width: 16vw;
  // }
}
.mapview_store_card_image {
  img {
    // width: 238px;
    // height: 155px;
    width: 200px;
    height: 200px;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    @media only screen and (max-width: "768px") {
      width: 140px;
    }
  }
}
.components_container {
  position: absolute;
  left: 0;
  width: 50%;
  // flex: 0 0 50%;
  padding-left: 20px;
  padding-top: 120px;
  padding-right: 20px;
}
.map_container {
  position: fixed;
  // flex: 0 0 50%;
  width: 50%;
  right: 0;
  margin-top: 80px;
  @media only screen and (max-width: "1400px") {
    flex: 0 0 46%;
  }
  @media only screen and (max-width: "1024px") {
    width: 100%;
    top: 200px;
    left: 0;
    margin-top: 80px;
  }
}
.components_container_main_heading {
  width: 300px;
  height: 14px;
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  color: #666666;
  .components_container_main_heading_product {
    font-weight: bold;
    padding-left: 5px;
  }
}
.components_container_second_heading {
  width: 240px;
  height: 33px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.69px;
  color: #333333;
}
.components_container_second_heading_two {
  width: 267px;
  height: 30px;
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.94px;
  color: #333333;
}
.mapview_filter {
  width: 685px;
  height: 60px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
}
.mapview_filter_text {
  display: inline-block;
  width: max-content;
  height: 14px;
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  color: #939090;
}
.mapview_filter_container {
  // width: 685px;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: "1024px") {
    width: 95vw;
  }
}
.filter_container {
  display: inline-block;
}
.filter_text {
  width: 32px;
  height: 21px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.16px;
  color: #333333;
  margin: 0 5px;
}
.close_map_view {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  z-index: 1;
  left: 20px;
  top: 25px;
  width: 34px;
  height: 34px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
}
.map_shop_card {
  position: relative;
  width: 220px;
  // height: 220px;
  max-height: 380px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 25px 0 rgba(139, 192, 221, 0.5);
  background-color: #ffffff;
  padding-bottom: 20px;
  margin: 20px;
}
.map_view_card_heart_container {
  width: 34px;
  height: 34px;
  border-radius: 8px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  position: absolute;
  right: 10px;
  top: 10px;
  text-align: center;
  padding-top: 5px;
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: none !important;
  box-shadow: none !important;
  width: 0 !important;
}
.mapview_shopping_center_address_container {
  width: 100%;
  display: flex;
  padding: 0 50px 0 10px;
  @media only screen and (max-width: 780px) {
    padding: 0 0 0 10px;
  }

  @media only screen and (max-width: 768px) {
    align-items: flex-start;
    img {
      width: 18px;
    }
  }
}
.map_shopping_center_address_container {
  align-items: center;
  // justify-content: space-around;
  width: 221px;
  padding: 0 !important;
  padding-left: 7px !important;
  margin-top: 5px;
}
.map_shop_image {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 220px;
  height: 220px;
  // height: 144px;
}
.map_shopping_centre_name {
  width: 221px;
  font-size: 14px;
  margin: 0 !important;
}
.map_view_shopping_centre_name {
  width: 100%;
  height: auto;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.67px;
  color: #333333;
  padding: 10px;
  padding-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media only screen and (max-width: 1000px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 0.08px;
    color: #333333;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 26px;
    margin-bottom: 15px;
  }
}
.map_shopping_centre_address {
  margin-left: 8px !important;
}
.mapview_shopping_centre_address {
  margin-left: 10px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.09px;
  color: #616161;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 2px !important;
  margin-top: 2px !important;

  @media only screen and (max-width: 1000px) {
    font-size: 12px;
  }
}
.search_in_your_area {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 191px;
  height: 34px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  position: absolute;
  z-index: 1;
  left: 25vw;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    left: 50vw;
  }
  top: 25px;
}
.search_in_the_zone_text {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #333333;
  margin-left: 6px;
}
.rotate_location_icon {
  -moz-transition: transform 1s;
  -webkit-transition: transform 1s;
  transition: transform 1s;
  transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
}
.text_and_selected {
  width: max-content;
  display: flex;
  align-items: center;
}
.filter_map_view {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.listing_filters_popup_map_view {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  // width: 1400px;
  // width: calc(100vw - 120px);
  width: 100%;
  height: max-content;
  // min-height: 309px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  padding: 0 10px;
  padding-bottom: 20px;
  @media only screen and (max-width: "1024px") {
    width: 95vw;
  }
}
.listing_filters_popup_search_filter_map_view {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  // width: calc(100vw - 150px);
  width: 100%;
  height: 60px;
  align-items: center;
  padding: 0 5px;
  margin: 0 10px;
}
.mapview_filter_container {
  .categories_item {
    margin: 5px;
  }
}
.listing_filters_popup_search_filter_map_view {
  .search_filter_text {
    margin-right: 0;
    width: 250px;
  }
}

.map_view_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}
.map_card_heart_container {
  width: 25px !important;
  height: 25px !important;
}
.map_card_tag_container {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.09px;
  color: #8d8b8b;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-left: 10px;
}
.map_large_card_tag_container {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.09px;
  color: #8d8b8b;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding: 5px 50px 5px 0;
}
