.common_footer {
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    align-items: center !important;
    text-align: center;
  }

  .footer_link_container {
    // height: 364px;
    background-image: linear-gradient(76deg, #25378d 0%, #1a71a5 100%);
    display: flex;
    justify-content: space-between;
    padding: 40px;
    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
      padding: 20px;
      align-items: center !important;
      text-align: center;
    }
  }

  .footer_links_column_one {
    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      margin: auto;
    }
  }

  .footer_links_column_two {
    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      margin: auto;
    }
  }

  .footer_links_column_three {
    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      margin: auto;
    }
  }

  .footer_links_column_btn {
    @media only screen and (max-width: 768px) {
      display: flex;
    }
  }

  .footer_link {
    width: auto;
    height: 32px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.8px;
    color: #ffffff;
    text-transform: uppercase;
    margin: 20px;
    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
      //margin-left: 50px;
      align-items: center;
    }
  }
  .footer_copyright_contiainer {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background: #25378d;
    @media only screen and (max-width: 500px) {
      flex-wrap: wrap;
    }
    // height: 60px;
  }
  .all_rights {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.51px;
    color: #ffffff;
    padding: 0 40px;
    @media only screen and (max-width: 500px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
    }
  }
  .powered_by {
    @media only screen and (max-width: 500px) {
      // padding: 0;
      // margin-left: 90px;
    }
  }
  .footer_button {
    @media only screen and (max-width: 1000px) {
      font-size: 12px !important;
      width: auto;
      display: block !important;
      margin: 20px 10px !important;
    }
  }
  .footer_links_column_four {
    @media only screen and (max-width: 768px) {
      flex: 0 0 100%;
      margin: 20px auto;
      border-top: 1px solid aliceblue;
    }
    .footer_button {
      @media (min-width: 400px) and (max-width: 768px) {
        width: 252px;
        height: 40px;
        display: inline-block !important;
        margin: 20px 40px !important;
        text-align: center !important;
        img {
          margin: 0 5px -4px 0;
        }
      }
      @media (max-width: 350px) {
        margin-left: 20% !important;
      }
      @media (min-width: 350px) and (max-width: 500px) {
        margin-left: 20% !important;
      }
      @media only screen and (max-width: 350px) {
        margin: 20px 20px !important;
      }
      @media only screen and (max-width: 700px) {
        width: 135px;
        height: 40px;
        display: inline-block !important;
        // margin: 20px 20px !important;
      }
    }
  }
  .footer_button_link {
    @media only screen and (max-width: 768px) {
      text-transform: uppercase;
      margin: 30px auto;
      width: max-content;
    }
  }
}
