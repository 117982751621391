.promp_page {
  min-height: calc(100vh - 56px);
}
.promo_header {
  background-image: url("../../assets/images/sale-header-backgound.png");

  // background-size: contain;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 24.76%;
  // padding-top: 20.76%; /* (img-height / img-width * container-width) */
  /* (853 / 1280 * 100) */
  position: relative;

  .promo_header_text {
    position: absolute;
    bottom: 50px;
    // left: 124px;
    left: 7%;
    // width: 646px;
    height: 47px;
    font-family: "Open Sans", sans-serif;
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.34;
    letter-spacing: 0.36px;
    color: #ffffff;
    @media only screen and (max-width: 1000px) {
      font-size: 20px;
      bottom: 30px;
    }
    @media only screen and (max-width: 900px) {
      font-size: 18px;
      bottom: 10px;
    }
    @media only screen and (max-width: 800px) {
      bottom: 5px;
      font-size: 16px;
    }
  }
}

.promo_offers_heading {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  // width: calc(100vw - 480px);
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: 18;
  // @media only screen and (max-width: 800px) {
  //   width: calc(100vw - 105px);
  // }
  height: 60px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  // margin: 20px auto;
  margin: 20px 0;

  // @media only screen and (max-width: 768px) {
  //   height: 50px;
  //   margin-left: 60px;
  // }
}

.items_carouesl_main {
  width: 100%;
  // width: calc(100vw - 100px);
  // margin: 0 auto;
  // padding: 40px 206px 40px 206px !important;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 3;
  grid-column-end: 17;
  // @media only screen and (max-width: 768px) {
  //   padding: 40px 127px 40px 115px !important;
  // }
}
.shoppping_promo_card {
  // max-width: 235px;
  border-radius: 8px;
  box-shadow: 0 0 25px 0 rgba(139, 192, 221, 0.5);
  background-color: #ffffff;
  margin: 35px auto;

  :hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 1300px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 1024px) {
    //padding-top: 50px;
    //padding-bottom: 55px;
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center;
  }
  @media only screen and (max-width: 768px) {
    // padding: 10px;
    font-size: 14px;
    text-align: center;
  }

  .promo_name {
    // height: 100%;
    text-align: center;
    height: 80px;
    max-width: 271px;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.67px;
    color: #333333;
    padding: 10px;
    @media only screen and (max-width: 1300px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 1024px) {
      //padding-top: 50px;
      //padding-bottom: 55px;
      margin-bottom: 10px;
      font-size: 16px;
      text-align: center;
      font-weight: bold;
    }
    @media only screen and (max-width: 768px) {
      //padding-top: 50px;
      // padding-bottom: 55px;
      margin-bottom: 10px;
      font-size: 14px;
      text-align: center;
      font-weight: bold;
    }
  }
}
.promo_section_heading {
  height: 30px;
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.94px;
  color: #333333;
  // margin: 30px 30px;
  margin: 30px 0;
  // margin-left: 20px;

  // @media only screen and (max-width: 1400px) {
  //   margin: 0;
  // }
}
.promo_offers_active {
  width: 92px;
  height: 21px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.13px;
  color: #b0b0b0;
}
// .img_card {
//   height: 214px;
//   @media only screen and (max-width: 768px) {
//     height: 125px;
//   }
//   @media only screen and (max-width: 1024px) {
//     padding-top: 10px;
//   }
// }
.promo_loader {
  // grid-row-start: 2;
  // grid-row-end: 3;
  // grid-column-start: 9;
  // grid-column-end: 10;
  margin: 100px 50% !important;
}
.promo_mage {
  width: 100%;
  //max-height: 214px;
  // padding: 30px;
  // height: 214px;
  height: 20vw;
  // object-fit: contain;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  // @media only screen and (max-width: 768px) {
  //   padding: 5px;
  //   height: 100px !important;
  // }
}

.left_button {
  top: 38%;
  // left: 55px;
  left: 0;
  height: max-content;
  // width: max-content;
  padding-right: 25px;
  // @media only screen and (max-width: 1600px) {
  //   position: absolute;
  //   left: -3%;
  // }
  // @media only screen and (max-width: 1500px) {
  //   position: absolute;
  //   left: -4%;
  // }
  // @media only screen and (max-width: 1400px) {
  //   position: absolute;
  //   left: -5%;
  // }
  // @media only screen and (max-width: 1100px) {
  //   position: absolute;
  //   left: -6%;
  // }
  // @media only screen and (max-width: 1000px) {
  //   position: absolute;
  //   left: -7%;
  // }
  // @media only screen and (max-width: 900px) {
  //   position: absolute;
  //   left: -8%;
  // }
  // @media only screen and (max-width: 900px) {
  //   position: absolute;
  //   left: -9%;
  // }
}
.right_button_promo {
  position: absolute;
  top: 38%;
  right: 0;
  height: max-content;
  // width: 50px;
  // width: max-content;

  // margin-right: -20px;
  // @media only screen and (max-width: 1600px) {
  //   position: absolute;
  //   right: -2%;
  // }
  // @media only screen and (max-width: 1500px) {
  //   position: absolute;
  //   right: -3%;
  // }
  // @media only screen and (max-width: 1400px) {
  //   position: absolute;
  //   right: -4%;
  // }
  // @media only screen and (max-width: 1100px) {
  //   position: absolute;
  //   right: -5%;
  // }
  // @media only screen and (max-width: 1100px) {
  //   position: absolute;
  //   right: -6%;
  // }
  // @media only screen and (max-width: 1000px) {
  //   position: absolute;
  //   right: -7%;
  // }
  // @media only screen and (max-width: 900px) {
  //   position: absolute;
  //   right: -8%;
  // }
}
.a_z_text {
  display: inline-block;
  // width: 46px;
  height: 21px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.16px;
  color: #333333;
  // margin-left: 10px;
  .ui.selection.dropdown {
    border: none;
    box-shadow: none;
    min-width: max-content;
    height: 20px;
    padding: 0 10px;
  }
  .ui.selection.active.dropdown .menu {
    border: none;
  }
  .ui.selection.visible.dropdown > .text:not(.default) {
    font-weight: inherit;
  }
  .ui.default.dropdown:not(.button) > .text,
  .ui.dropdown:not(.button) > .default.text {
    color: #333333;
  }
  .text {
    display: inline-block;
    // width: 46px;
    // height: 21px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.16px;
    color: #333333;
    margin-right: 10px;
    min-width: 42px;
  }
}
.distance_text {
  display: inline-block;
  // width: 83px;
  height: 21px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.16px;
  color: #333333;
  margin-left: 10px;
  .text {
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.16px;
    color: #333333;
    min-width: 42px;
  }
}
.a_z_filter {
  border-bottom: 1px solid #d5d5d5;
  display: inline-block;
  margin: 0 10px;
  padding-bottom: 5px;
  min-width: 128px;
}
.promo_heading_text {
  display: inline-block;
  width: 300px;
  height: 14px;
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  color: #939090;
}

.promo_filters {
  flex: 0 0 340px;
}

.promo_content_section {
  margin-bottom: 250px;
  grid-template-columns: repeat(18, 1fr);
  grid-template-rows: auto auto;
  padding-left: 40px;
  padding-right: 30px;
  @media (min-width: 1000px) and (max-width: 1024px) {
    padding-left: 60px;
  }
}
.no_data_promo {
  // margin: 250px auto;
  width: max-content;
  font-family: "Open Sans", sans-serif;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 9;
  grid-column-end: 10;
  margin: 100px auto !important;
}

.main_wrapper_cards_mall_promo {
  // padding-right: 135px;
  position: relative;
  max-width: 100vw;
}

.mall_card_item_wrapper_promo {
  width: 20vw;
  //padding: 10px!important;
  //margin-left: 35px!important;
}

// .bmWelE {
//   right: -82px !important;
// }
