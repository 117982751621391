// .shoppping_centre_card {
//   @media only screen and (max-width: 375px) {
//     width: 100vw;
//     margin: 0px;
//   }
// }
.shopping_center_products {
  // padding: 60px 98px 60px 115px;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 19;
  // padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  // @media (min-width: 768px) and (max-width: 900px) {
  //   padding-left: 30px;
  //   // padding-right: 10px;
  // }
}
.shopping_center_details_container_products {
  // flex-wrap: wrap;
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // @media only screen and (max-width: 1024px) {
  //   grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
  // }
  // @media only screen and (max-width: 830px) {
  //   grid-template-columns: repeat(auto-fill, minmax(195px, 1fr));
  //   grid-gap: 20px;
  // }
  // @media (min-width: 1025px) and (max-width: 1300px) {
  //   grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  // }
  // grid-gap: 30px;

  flex-wrap: wrap;
  grid-row-start: 2;
  grid-row-end: 9;
  grid-column-start: 1;
  grid-column-end: 19;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin-top: 110px;
  margin-bottom: 60px;
}
.shoppping_centre_product_card {
  padding-bottom: 40px !important;
  padding-top: 5px;
  text-align: center;
  //max-width: 328px;
  img {
    max-height: 250px !important;
    width: 100% !important;
    margin-top: -5px !important;
  }
  .promo_text {
    width: auto;
    height: 16px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.39px;
    color: #dd0202;
    margin: 0 10px;
    margin-bottom: 20px;
  }
}
.shop_product_name {
  width: 100%;
  height: auto;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.67px;
  color: #333333;
  padding: 10px;
  padding-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media only screen and (max-width: 1000px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 0.08px;
    color: #333333;
  }
  @media only screen and (max-width: 1024px) {
    margin-top: 5px;
    margin-bottom: 15px;
  }
}

.old_new_price_container {
  margin: 10px;

  .old_price {
    width: max-content;
    height: 22px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.39px;
    color: #7a7a7a;
    text-decoration: line-through;
    margin-right: 20px;
  }

  .new_price {
    width: max-content;
    height: 24px;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.57px;
    color: #333333;
  }
}

.shop_map_heading {
  margin-bottom: 30px;
}

.shop_tags {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.09px;
  color: #8d8b8b;
  margin-right: 5px;
}
//
//.react-photo-gallery--gallery > div {
//  flex-flow: row !important;
//  overflow: hidden;
//
//  // height: 400px;
//  > * img {
//    height: 100%;
//  }
//}

//.react-photo-gallery--gallery img {
//  height: 470px;
//  width: auto;
//  margin-right: 15px !important;
//}

.shop_gallary {
  margin-left: 5px;
  display: flex;
  height: 50%;
  padding: 12px;

  // @media (min-width: 768px) and (max-width: 1024px) {
  //   margin-left: 60px;
  // }
}

.shop_Offer_Image {
  // max-height: 213px;
  // height: 214px;
  height: 20vw;
  // margin-top: 10px;
  // padding: 10px;
}

.shoppping_centre_product {
  position: relative;
  display: flex;
  flex-direction: column;
  //width: 327px;
  //width: max-content;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 25px 0 rgba(139, 192, 221, 0.5);
  background-color: #ffffff;
  padding-bottom: 20px;
  // margin: 20px;
  flex: 0 1 30%;
  //max-width: 30%;
  //@media only screen and (min-width: 1600px) {
  //  max-width: 23%;
  //  margin: 17px;
  //}
  @media only screen and (max-width: 1024px) {
    flex-basis: 50%;
  }
  @media only screen and (max-width: 768px) {
    flex-basis: 100%;
    height: auto;
  }

  @media only screen and (max-width: 375px) {
    flex: 0 0 100%;
    // max-width: 50%;
  }
  @media only screen and (max-width: 1700px) {
    // margin: 18px;
  }
  @media only screen and (max-width: 1024px) {
    padding-bottom: 10px !important;
  }
  // @media only screen and (max-width: 768px) {
  //   flex-direction: row;
  // }
  // flex: 1 1 327px;
  // > img {
  //   width: 100%;
  // }
}

.shoppping_centre_product_card > img {
  max-height: 240px;
  width: 100%;
  @media only screen and (max-width: 1024px) {
    max-height: 130px;
  }
  @media only screen and (max-width: 375px) {
    width: 100%;
  }
}

.shop_loader {
  margin: 200px 50% !important;
}
.mall_details_dots .control-dots {
  display: none;
}

.shop-details-btn-div {
  @media only screen and (max-width: 400px) {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
}
