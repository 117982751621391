.List {
  border: 1px solid #d9dddd;
}

.ListItemEven,
.ListItemOdd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListItemEven {
  background-color: #f8f8f0;
}

.cities_main_container {
  position: relative;
  width: 100%;
}

.button_city_clear {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  position: absolute;
  right: 50px;
  top: 15px;
}
.css-yk16xz-control:focus {
  outline: none !important;
}
.css-g1d714-ValueContainer:focus {
  outline: none !important;
}
.css-yk16xz-control {
  height: 50px;
  border-radius: 8px !important;
  flex-wrap: nowrap !important;
}
.css-g1d714-ValueContainer {
  height: 50px;
}
.css-1pahdxg-control {
  border-color: #cdcdcd !important ;
  box-shadow: none !important;
  border-radius: 8px !important;
  height: 50px;
  flex-wrap: nowrap !important;
}
.new-landing-search {
  .css-yk16xz-control {
    border-style: none !important;
  }
  .cities_main_container {
    border: 2px solid #dd3978;
    border-radius: 8px !important;
  }
  .css-1pahdxg-control {
    border-style: none !important;
  }
}
