.navigation_modal {
  border-radius: 0 !important;
  height: 150px !important;
  box-shadow: 0 0 34px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  //   padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
}
.tab_nav_name {
  margin-top: 88px !important;
  display: flex !important;
  justify-content: space-evenly !important;
}
.no_button {
  background: none !important;
  border: none !important;
  outline: none !important;
  cursor: pointer !important;

  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: 0.7px;
  color: #333333;
}
