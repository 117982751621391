.reservations_header {
    background-image: url("../../assets/images/reservations-banner.jpg");
  
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 24.76%; /* (img-height / img-width * container-width) */
    /* (853 / 1280 * 100) */
    position: relative;
  
    .reservations_header_text {
      position: absolute;
      bottom: 50px;
      // left: 124px;
      left: 7%;
      width: 646px;
      height: 47px;
      font-family: "Open Sans", sans-serif;
      font-size: 35px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.34;
      letter-spacing: 0.36px;
      color: #ffffff;
      @media only screen and (max-width: 1000px) {
        font-size: 20px;
        bottom: 30px;
      }
      @media only screen and (max-width: 900px) {
        font-size: 18px;
        bottom: 10px;
      }
      @media only screen and (max-width: 800px) {
        bottom: 5px;
        font-size: 16px;
      }
    }
  }

.reservations {
    display: grid;
    grid-template-columns: repeat(18, 1fr);
    padding-top: 60px;
    padding-bottom: 150px;
    padding-left: 30px;
    padding-right: 30px; 
    &-card {
        border-radius: 8px;
        box-shadow: 0 0 25px 0 rgba(139, 192, 221, 0.5);
        background-color: #ffffff;
        grid-column-start: 1;
        grid-column-end: 7;
        &-header {
          padding: 18px 16px 0 16px;
        }
        .title {
            font-family: "Open Sans", sans-serif;
            font-size: 22px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.94px;
            color: #333333;
            text-transform: uppercase;
            margin-bottom: 24px;
        }
        .filters {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            span {
                font-family: "Open Sans", sans-serif;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.6px;
                color: #333333;
                margin-right: 20px;
                cursor: pointer;
            }
            .selected {
                padding: 6px 12px 7px 11px;
                border-radius: 16px;
                background-image: linear-gradient(64deg, #3b58e0 0%, #6bb5fc 100%);
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold!important;
                color: #ffffff;
                text-transform: uppercase;
            }
        }
    }
    &-section {
        grid-column-start: 7;
        grid-column-end: 19;
        padding: 0 50px;
        &-name {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }
        &-info {
          margin-bottom: 20px;
        }
        &-label {
          font-family: "Open Sans", sans-serif;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.6px;
          color: #333333;
        }
        &-text {
          font-family: "Open Sans", sans-serif;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          color: #333333;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0.86px;
        }
    }
    &-list {
        overflow-y: auto;
        height: 490px;
        background-color: #f5f5f5;
        .element {
            padding-top: 13px;   
            padding-bottom: 13px;   
            padding-left: 16px;
            padding-right: 16px;
            background-color: #f5f5f5;
            cursor: pointer; 
            border-bottom: 1px solid #cccccc;
            &:last-child {
              border-bottom: none;
            }
            .date {
              font-family: "Open Sans", sans-serif;
              font-size: 13px;
              font-weight: bold;
              letter-spacing: 0.56px;
              color: #333333;
              margin-bottom: 9px;
            }
            .storeName {
              font-family: "Open Sans", sans-serif;
              font-size: 18px;
              font-weight: bold;
              letter-spacing: 0.77px;
              color: #333333;
              margin-bottom: 12px;
            }
        }
    }
}
.booking-status-ACCEPTED {
  padding: 3px 6px 2px 7px;
  border-radius: 3px;
  background-color: #2dd73b;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.13px;
  color: #ffffff;
  text-transform: uppercase;
}

.booking-status-REFUSED, .booking-status-ABORTED {
  padding: 3px 6px 2px 7px;
  border-radius: 3px;
  background-color: #f12121;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.13px;
  color: #ffffff;
  text-transform: uppercase;
}

.booking-status-PENDING {
  padding: 3px 6px 2px 7px;
  border-radius: 3px;
  background-color: #aab0ab;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.13px;
  color: #ffffff;
  text-transform: uppercase;
}

.reservations-button-content {
  display: flex;
  justify-content: flex-end;
}
.reservations-button {
    width: 200px!important;
    border:none;
    cursor: pointer;
    border-radius: 8px !important;
  width: 153px;
  height: 44px;
  background: linear-gradient(
    to top,
    #ab2814 1%,
    #ec6338 15%,
    #f39149
  ) !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.4px;
  color: #ffffff !important;
  box-shadow: none !important;
  outline: none;
}