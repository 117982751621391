.privacy_terms {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  width: 100%;
  height: max-content;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.96px;
  color: #333333;
  margin: 20px 0;
}

li {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  width: 100%;
  height: max-content;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.96px;
  color: #333333;
}
.privacy_content {
  padding: 200px 50px;
  padding-top: 120px;
 // padding-bottom: 1100px;
  @media only screen and (max-width: 500px) {
    padding: 35px;
    padding-top: 100px;
    //padding-bottom: 1100px;
  }
}
.privacy_title {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  width: 100%;
  height: max-content;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.96px;
  color: #333333;
  margin: 20px 0;
  padding-top: 20px;
}
.privacy_heading {
  width: max-content;
  height: max-content;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.21px;
  text-align: center;
  color: #333333;
  margin-bottom: 30px;
  margin-top: 20px;
}

.privacy-date {
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  font-size: 17px;
}
