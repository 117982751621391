.main_cotainer {
  padding: 150px 0;
}
.user_key_value_container {
  display: flex;
}
.user_key_name {
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.05px;
  text-align: right;
  color: #333333;
}

.user_key_value {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.06px;
  color: #333333;
  margin-left: 80px;
  padding-top: 6px;
}
.user_key_email {
  margin-left: 126px;
}
.new_password_input {
  height: 38px;
  margin-top: 10px;
  margin-left: 40px;
}
.confirm_password_input {
  height: 38px;
  margin-top: 10px;
  margin-left: 6px;
}
.user_details {
  width: max-content;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 0 12px 0 rgba(139, 192, 221, 0.5);
  background-color: #ffffff;
  margin: 0 auto;
}
.user_key_password {
  padding-top: 8px;
}
.password_reset_button {
  margin-top: 24px !important;
  width: max-content !important;
  border: none !important;
  outline: none !important;
  cursor: pointer;
}
.password_reset_button_2 {
  margin-left: 24% !important;
}
