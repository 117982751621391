.store-card {
  position: relative;
  height: 100%;
  min-height: 460px;
  @media (min-width: 1600px) and (max-width: 1698px) {
    min-height: 480px;
  }
  @media only screen and (max-width: 1400px) {
    min-height: 420px;
  }
  @media (min-width: 1100px) and (max-width: 1170px) {
    min-height: 440px;
  }
  @media only screen and (max-width: 1024px) {
    min-height: 400px;
  }
  @media (min-width: 790px) and (max-width: 900px) {
    min-height: 360px;
  }
  @media only screen and (max-width: 800px) {
    // min-height: 440px;
    min-height: 440px;
  }
  @media (min-width: 791px) and (max-width: 800px) {
    // min-height: 440px;
    min-height: 380px;
  }
  @media only screen and (max-width: 700px) {
    height: 200px;
  }
  @media (min-width: 540px) and (max-width: 700px) {
    min-height: 420px;
  }
  @media only screen and (max-width: 540px) {
    min-height: 580px;
  }
  //max-height: 334px;
  // @media only screen and (max-width: 1024px) {
  //   max-height: 280px;
  // }
  border-radius: 8px;
  // box-shadow: 0 0 14px 0 rgba(101, 179, 46, 0.23);
  box-shadow: 0 0 25px 0 rgba(139, 192, 221, 0.5);
  background-color: #fff;
  margin-bottom: 21px;
  cursor: pointer;
  position: relative;
  width: 100%;
  padding-top: 75%;
}
.store-card-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.card-heart-container-new {
  width: 34px;
  height: 34px;
  border-radius: 8px;
  z-index: 1;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin: auto;
  }
}
.heart-button-new {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding-top: 5px;
}
.store-card .store-image {
  background: url("../../assets/images/shoping-centre.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  // height: 300px;
  padding-top: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.store-card .name-description {
  width: 100%;
  max-height: 64px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: 0.67px;
  color: #333333;
  padding: 0px 15px 5px 15px;
  // text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* white-space: nowrap; */
  // margin-bottom: 16px;
}
.address-description img {
  align-self: flex-start;
  // margin-top: -5px;
  margin-right: 5px;
}
.store-card .address-description {
  width: 100%;
  //max-height: 25px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.09px;
  color: #616161;
  padding: 0 15px 15px 15px;
  display: flex;
  align-items: center;
  // span {
  // text-overflow: ellipsis;
  // overflow: hidden;
  // white-space: nowrap;
  // }
}
.card_tag_container {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.09px;
  color: #8d8b8b;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding: 5px 15px;
}
