form.ui.form input[type="text"] {
  border-color: #cdcdcd !important;
  border-radius: 8px !important;
}
.user_details_content {
  display: flex;
  padding-top: 150px;
  // padding-left: 140px;
  padding-bottom: 150px;
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  // @media only screen and (max-width: 1024px) {
  //   padding-left: 55px;
  // }
}
.user_details_navigation_card {
  // width: 447px;
  height: 446px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  // flex: 0 0 30%;
  grid-column-start: 1;
  grid-column-end: 6;
  margin-left: 50px;
  @media only screen and (max-width: 1000px) {
    margin-left: 25px;
  }
  //   margin-top: 200px;
}
.user_details_username {
  width: 100%;
  // padding: 52px 0;
  padding-top: 40px;
  padding-bottom: 64px;
  height: 27px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  @media only screen and (max-width: 1024px) {
    font-size: 15px;
  }
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #333333;
}
.user_details_uername_text {
  width: 100%;
  height: max-content;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #333333;
}
.user_details_username_container {
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: solid 1px #d5d5d5;
  border-left: 0;
  border-right: 0;
}
.user_details_navigation_card_section_one {
  background: none;
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: start;
  // width: 447px;
  height: 60px;
  background-color: #f8f8f8;
  width: 100%;
  border: solid 1px #d5d5d5;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 12px 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  @media only screen and (max-width: 1400px) {
    font-size: 11px;
  }
  @media only screen and (max-width: 800px) {
    font-size: 8px;
  }
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: 0.7px;
  color: #747474;
}
.card_naviation_active {
  position: relative;
  background-color: #ffffff;
  color: #333333;
}
.selected_scroll {
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 60px;
  background: linear-gradient(to top, #ab2814 1%, #ec6338 15%, #f39149);
}
.profile_logut {
  background: none;
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-top: 30px;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: solid 2px #e60000;
  color: #e60000;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: center;
}
.user_detailed_first_section_heading {
  width: max-content;
  height: 30px;
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.94px;
  color: #333333;
  margin-left: 10px;
}
.user_detailed_section {
  // flex: 0 0 70%;
  position: relative;
  // padding-right: 53px;
  grid-column-start: 6;
  grid-column-end: 19;

  // @media only screen and (max-width: 1024px) {
  //   padding-right: 20px;
  // }
  // @media only screen and (max-width: 768px) {
  //   max-width: 500px;
  // }

  // @media (min-width: 769px) and (max-width: 1024px) {
  //   max-width: 65vw;
  // }
}
.user_detailed_first_section {
  padding: 0 50px;
  form.ui.form input[type="text"] {
    border-color: #cdcdcd !important;
    border-radius: 8px !important;
  }
  .ui.selection.active.dropdown .menu {
    border-color: #cdcdcd !important;
  }
  .selection {
    border-color: #cdcdcd !important;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.user_detailed_first_section .angle {
  position: absolute;
  right: 4px;
  top: 11px;
  color: #f39149;
  padding-left: 9px;
  border-left: 1px solid #d8d8d8;
  font-size: 30px !important;
  padding-top: 8px !important;
}
.user_profile_first_section_left {
  position: absolute;
  left: 24px;
  bottom: 25px;
  width: 208px !important;
  @media only screen and (max-width: 1024px) {
    width: 140px !important;
  }
  height: 50px !important;
  border-radius: 8px !important;
  border: solid 2px #cdcdcd;
  margin-top: 20px !important;
  background: #cdcdcd !important;
  color: #747474 !important;
}
.del_button_container {
  position: absolute;
  right: 5px;
  bottom: 0;
  .delete_button {
    width: 208px !important;
    height: 50px !important;
    border-radius: 8px !important;
    // border: solid 2px #cdcdcd;
    margin-top: 20px !important;
    background: #ff0000 !important;
    color: #ffffff !important;
    margin-right: 240px;
    margin-bottom: 28px;
    @media (min-width: 1024px) and (max-width: 1200px) {
      width: 150px !important;
    }
    @media only screen and (max-width: 1024px) {
      width: 140px !important;
      margin-right: 160px;
    }
  }
}
.user_profile_first_section_right {
  position: absolute;
  right: 5px;
  bottom: 25px;
  width: 208px !important;
  @media only screen and (max-width: 1024px) {
    width: 140px !important;
    font-size: 12px !important;
  }
  height: 50px !important;
  border-radius: 8px !important;
  border: solid 2px #cdcdcd;
  background: linear-gradient(
    to top,
    #ab2814 1%,
    #ec6338 15%,
    #f39149
  ) !important;
  color: #ffffff !important;
}
.name_sirname_container {
  display: flex;
  flex-wrap: wrap;
  .field {
    flex: 1 1 50%;
  }
  input {
    margin-left: 0 !important;
  }
}
.user_profile_checkbox_points {
  margin-top: 20px;
  label {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
  }
}
.user_detailed_second_section {
  padding: 0 50px;
  padding-bottom: 50px;
  padding-right: 52px;
  @media only screen and (max-width: 1024px) {
    padding-right: 20px;
  }
  height: 100%;
  .user_detailed_first_section_heading {
    margin-bottom: 30px;
    margin-left: 20px;
  }
  input[type="text"] {
    margin: 0;
  }
  .legal_info {
    margin: 20px 0;
  }
  .privacy_points {
    margin: 0;
    font-size: 16px;
  }
}
.user_detailed_second_section_container {
  position: relative;
  padding-bottom: 100px;
  .user_profile_first_section_left {
    left: 0;
  }
  input[type="text"] {
    margin-left: 0 !important;
  }
  .telephone {
    width: 50%;
    input {
      margin-left: 0 !important;
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}
.user_detailed_second_section_container input[type="text"] {
  margin-left: 0px;
}
.user_profile_checkbox_points_delete {
  margin-bottom: 25px;
  label {
    width: max-content;
    height: 19px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 1.12px;
    color: #333333;
  }
}

.delete_input_button {
  display: flex;
  align-items: flex-start;
  * {
    flex: 1 1 50%;
  }
}
.account_delete_button {
  width: 208px !important;
  height: 50px !important;
  border-radius: 8px !important;
  border: solid 2px #cdcdcd;
  background: linear-gradient(
    to top,
    #ab2814 1%,
    #ec6338 15%,
    #f39149
  ) !important;
  color: #ffffff !important;
}
.account_delete_warning {
  width: 100%;
  height: 42px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.7px;
  color: #333333;
}
.city_detail_container {
  display: flex;
  justify-content: space-between;
  .city_detail_item {
    position: relative;
    width: 33%;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
  // .postal_code {
  //   flex: 0 0 30%;
  // }
  // .city {
  //   flex: 0 0 40%;
  // }
  // .province {
  //   flex: 0 0 30%;
  // }
}
.profile_address_container {
  display: flex;
  .address_shipping {
    position: relative;
    width: 50%;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  .shipping_street {
    position: relative;
    width: 50%;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
  .address {
    flex: 0 0 70%;
  }
  .street_number {
    flex: 0 0 30%;
  }
}
.address_name {
  width: 100%;
  height: 27px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.86px;
  color: #333333;
  margin-left: 20px;
}
.address_card {
  width: 100%;
  // height: max-content;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  padding: 20px;
}
.adrress_edit {
  display: flex;
  justify-content: space-between;
}
.address_card_heading {
  width: max-content;
  height: max-content;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.6px;
  color: #333333;
}
.edit_text {
  background: none;
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  border: none;
  outline: none;
  width: max-content;
  height: max-content;
  font-family: "Opem Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.75;
  letter-spacing: 0.05px;
  color: #e45c34;
  text-decoration: underline;
}
.holder_name {
  width: max-content;
  height: max-content;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.06px;
  color: #333333;
}
.address_first_line {
  width: 100%;
  height: max-content;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.06px;
  color: #333333;
}
.address_telephone {
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0.05px;
  color: #828080;
}
.adderess_cards_container {
  display: flex;
  flex-wrap: wrap;
  // margin-right: 44px;
  margin-bottom: 30px;
  .address_card {
    @media only screen and (max-width: 1024px) {
      max-width: 100%;
    }
    flex: 1 1 50%;
    max-width: 48%;
    margin-left: 20px;
    margin-bottom: 20px;
    position: relative;
    // margin-right: 30px;
  }
}
.new_address_text {
  width: 100%;
  height: max-content;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.86px;
  color: #333333;
}

.order_summary_heading {
  width: 100%;
  height: 30px;
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.94px;
  color: #333333;
}

.order_list_text {
  width: 384px;
  height: 27px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.86px;
  color: #333333;
}

.order_search_header {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: relative;
}
.order_filter_text {
  width: 32px;
  height: 21px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.16px;
  color: #333333;
  padding-left: 10px;
}
.total_orders {
  width: 94px;
  height: 15px;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: #858585;
  flex: 0 0 20%;
}
.order_detail_card {
  position: relative;
  width: 420px;
  @media (min-width: 1025px) and (max-width: 1400px) {
    width: 327px;
  }
  // height: 157px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  .green_bar {
    position: absolute;
    left: 0;
    width: 12px;
    height: 100%;
    background-color: #4abd45;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .red_bar {
    position: absolute;
    left: 0;
    width: 12px;
    height: 100%;
    background-color: red;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .yellow_bar {
    position: absolute;
    left: 0;
    width: 12px;
    height: 100%;
    background-color: #d1cf25;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .order_key_value {
    padding: 20px;
  }
}
.profile_footer_container {
  margin-top: 200px;
}
// .order_card_item_wrapper {
// width: 420px !important;
// }
.order_carousel_container {
  max-width: 63vw;
  margin-top: 50px;
}
.right_button_order {
  top: 191px !important;
  right: -3px !important;
  height: max-content !important;
}
.left_button_order {
  height: max-content !important;
  top: 190px !important;
  left: 5px !important;
}
.address_detail_name_order {
  width: max-content;
  height: auto;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.06px;
  text-align: right;
  color: #333333;
}

.address_detail_value_order {
  width: max-content;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
  height: auto;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.07px;
  color: #333333;
  padding-left: 20px;
}
.size_name {
  width: 70px;
  height: 19px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: #7a7a7a;
}
.size_value {
  @extend .size_name;
  font-weight: 800;
  color: #333333;
}
.profile_order_description {
  padding: 20px;
}
.profile_order_description_heading {
  width: 100%;
  height: 18px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: 0.33px;
  color: #333333;
}

.promo_discount_profile {
  width: 139px;
  height: 19px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: #dd0202;
}
.profile_old_price {
  text-decoration: line-through;
  width: 58px;
  height: 19px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  color: #7a7a7a;
}
.profile_new_price {
  width: 61px;
  height: 22px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.39px;
  color: #333333;
}
.profile_order_card_details {
  display: flex;
  margin: 20px 0;
}
.order_number_key {
  width: max-content;
  height: 27px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #333333;
}
.order_number_value {
  @extend .order_number_key;
  font-size: 20px;
  letter-spacing: 0.86px;
  color: #ed6b3b;
  margin-left: 20px;
}
.order_number_container {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}
.order_history_print {
  @media only screen and (max-width: 1024px) {
    max-width: 427px;
  }
  // padding: 30px;
  border-radius: 8px;
  background-color: #ffffff;
}
.order_detail_text {
  margin: 50px 0;
  margin-top: 120px;
  width: 100%;
  height: 27px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.86px;
  color: #333333;
}
.section_5_button_container {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  padding-top: 0;
}
.section_5_left_button {
  width: 208px;
  height: 50px;
  border-radius: 8px !important;
  border: solid 2px #e60000 !important;
  background-color: #e60000 !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.35px !important;
  text-align: center !important;
  color: #ffffff !important;
}
.section_5_right_button {
  width: 208px;
  height: 50px;
  border-radius: 8px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.35px !important;
  text-align: center !important;
  color: #ffffff !important;
  border: solid 2px #cdcdcd;
  background: linear-gradient(
    to top,
    #ab2814 1%,
    #ec6338 15%,
    #f39149
  ) !important;
}
.print_button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.printable_content {
  padding: 30px;
  padding-bottom: 0;
}

.details_divider {
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
  margin: 20px 0;
}
.order_search_header {
  input {
    // width: 100%;
    border: 0;
    outline: 0;
    border-bottom: 1px solid #d8d8d8;
    padding: 0;
    font-family: "Open Sans", sans-serif;
    padding-right: 20px;
    // height: max-content;
  }
}
.search_order_container {
  flex: 0 0 60%;
  position: relative;
  input {
    width: 100%;
    border: 0;
    outline: 0;
    border-bottom: 1px solid #d8d8d8;
    padding: 0;
    font-family: "Open Sans", sans-serif;
    padding-right: 20px;
    // height: max-content;
  }
  .search_field_order {
    position: absolute;
    right: 0;
  }
}
.order_icon_text_container {
  flex: 0 0 20%;
  text-align: end;
}
.modal_search_header {
  display: flex;
  align-items: center;
}
.order_search__modal {
  width: 100%;
  height: 370px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  // position: relative;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  // right: 27px;
}
.order_checkbox_tick {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: solid 1px #092853;
}
.order_filter_checbox {
  position: relative;
  margin-top: 20px;
}
.order_checkbox_text {
  margin-left: 10px;
  width: max-content;
  height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.96px;
  color: #333333;
  text-align: start;
  position: absolute;
  top: 0;
}
.checkbox_heading {
  width: 100%;
  height: 22px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.51px;
  color: #333333;
}
.filter_content {
  display: flex;
  justify-content: space-between;
}
.order_date_container {
  position: relative;
  .order_date_calendar {
    position: absolute;
    right: 5px;
    top: 10px;
    border-left: solid 1px #d5d5d5;
    padding-left: 10px;
    pointer-events: none;
  }
  input {
    height: 40px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 8px;
    padding: 5px;
  }
  .react-datepicker__close-icon {
    right: 36px !important;
  }
}
.orderFromDate {
  width: max-content;
  height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.96px;
  color: #333333;
  position: absolute;
  z-index: 2;
  left: -40px;
  top: 5px;
}
.orderTillDate {
  width: max-content;
  height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.96px;
  color: #333333;
  position: absolute;
  top: 8px;
  left: -33px;
  z-index: 2;
}
.date_selector_container {
  display: flex;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
}
.order_date_container_second {
  margin-left: 50px;
  @media only screen and (max-width: 1024px) {
    margin-left: 0px;
    margin-top: 20px;
  }
}
.order_modal_button {
  position: absolute;
  right: 12px;
}
.order_search__modal {
  padding: 20px;
}
.profile_modal_open {
  background: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
}
.confirmation_loader_middle {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
}

.user_info_inputs {
  input {
    width: 100% !important;
    font-family: "Open Sans", sans-serif !important;
  }
}

.address_form_container {
  padding-left: 20px;
  padding-right: 5px;
}
.shipping_address_delete {
  position: absolute;
  right: 0px;
  top: 5px;
}
.shipping_address_delete:hover {
  cursor: pointer;
}

// .user_detailed_second_section_pdf {
//   padding-right: 445px !important;
//   @media only screen and (max-width: 1024px) {
//     padding-right: 10px !important;
//   }
// }

.notes_for_deleivery {
  width: 100% !important;
}
.no_orders_found {
  width: max-content;
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.94px;
  color: #333333;
  padding: 200px 300px;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding: 200px 120px;
  }
}
.delteUserError {
  position: absolute;
  top: 55px;
  left: 14px;
  color: red;
  font-family: "Open Sans", sans-serif;
}
.orderIdLoader {
  position: absolute !important;
  top: 520px !important;
  left: 50% !important;
}
.profile_order_image_cotaner {
  img {
    height: 146px;
  }
}
.gender_dropdown_user_profile {
  .selection {
    width: 100% !important;
  }
}
.user_profile_lastname {
  input {
    width: 100% !important;
  }
}
.user_profile_dob {
  input {
    width: 100% !important;
  }
}
.user_profile_phone {
  input {
    width: 100% !important;
  }
}
.user_profile_city {
  width: 100% !important;
}

.address_values_container {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.user_profile_validation_message {
  position: absolute;
  top: 52px;
  left: 15px;
  color: red;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
}

.user_profile_validation_message_password {
  position: absolute;
  top: 55px;
  color: red;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  padding-right: 10px;
  left: 5px;
}

.user_dob {
  position: relative;
  margin-bottom: 10px;
  .react-datepicker-wrapper {
    width: 100%;
    position: relative;
  }
  .user_dob_calendar {
    position: absolute;
    right: 10px;
    top: 15px;
    border-left: 1px solid #d8d8d8;
    padding-left: 10px;
    pointer-events: none;
  }
  .react-datepicker-popper {
    top: -20px !important;
  }
}
