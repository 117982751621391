.landing_header {
  // background-image: url('../../assets/images/phone-group-img.png');
  // height: 878px;
  // background-size: contain;
  // background-position: right -155px top -47px;
  // background-color: #4c67e6;
  background-image: linear-gradient(69deg, #3b58e0 0%, #6bb5fc 100%);
  padding-bottom: 168px;
}
.landing_navbar {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
  // padding: 20px;
  // background-color: #4c67e6;
}
.logo_image {
  margin: 14px 0 0 72px;
  @media only screen and (max-width: 1350px) {
    margin: 14px 0 0 10px;
  }
  @media only screen and (max-width: 1100px) {
    width: 200px;
  }
  @media only screen and (max-width: 1050px) {
    width: 150px;
  }
  @media only screen and (max-width: 768px) {
    width: 140px;
    margin: unset;
    margin-left: 20px;
  }
}
.logo_image_image {
  margin: 23px 0 0 100px;
  width: 150px;
}
.logo_name_mobile {
  background-image: url("../../assets/images/mobile-header-backgrounnd.png");
  background-size: cover;
  height: 181px;
  width: 100%;
  // width: 471px;
  background-repeat: no-repeat;
  // padding: 20px 0 0 20px;
  background-position: left 0px top -21px;
}
.logo_name {
  background-image: url("../../assets/images/retapp-logo-background.png");
  height: 181px;
  width: 471px;
  // padding: 20px 0 0 20px;
  background-position: left 0px top -21px;
  background-repeat: no-repeat;

  @media (min-width: 1025px) and (max-width: 1767px) {
    background-size: contain;
    height: auto;
    width: auto;
    background-position: initial;
  }
  // @media only screen and (max-width: 1160px) {
  //   width: 280px;
  // }
  // @media only screen and (max-width: 1100px) {
  //   width: 200px;
  // }
  // @media only screen and (max-width: 1050px) {
  //   width: 160px;
  // }
  @media only screen and (max-width: 1024px) {
    background-size: contain;
    width: 50%;
  }
  @media only screen and (max-width: 767px) {
    height: max-content;
  }
  @media only screen and (max-width: 767px) {
    height: unset;
    width: unset;
  }
}
.nav-link {
  font-size: 16px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.8px;
  padding: 20px;
  color: #ffffff;
  font-family: "Open sans", sans-serif;
  background: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  @media only screen and (max-width: 1350px) {
    padding: 10px;
  }
}
.landing_register_button {
  border-radius: 8px !important;
  width: 153px;
  height: 44px;
  border: solid 2px #ec6338 !important;
  background-color: #ffffff !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.4px !important;
  color: #ec6338 !important;
  padding: 10px !important;
  margin-right: 20px !important;
  @media only screen and (max-width: 1050px) {
    width: 120px;
  }
}
.landing_login_button {
  border-radius: 8px !important;
  width: 153px;
  height: 44px;
  background: linear-gradient(
    to top,
    #ab2814 1%,
    #ec6338 15%,
    #f39149
  ) !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.4px;
  color: #ffffff !important;
  box-shadow: none !important;
  outline: none;
}
.landing_download_button_div {
  display: flex !important;
  justify-content: center !important;
  margin-left: 18px !important;
}
.button_container {
  z-index: 2;
  margin: 20px 20px 0 0;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}
.link_container {
  @media only screen and (max-width: 1350px) {
    margin-right: 0px;
  }
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}
.landing_intro_container {
  padding-left: 130px;
  padding-top: 200px;
  @media only screen and (max-width: 1024px) {
    padding-left: 30px;
  }
  @media only screen and (max-width: 500px) {
    padding-top: 630px;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    padding-top: 1100px;
  }
  @media (min-width: 320px) and (max-width: 640px) {
   padding-right: 5px;
    padding-left: 10px;
    margin: auto;
  }
}

.landing_intro_text_heading {
  font-family: "Open Sans", sans-serif;
  width: 725px;
  // height: 114px;
  font-size: 50px;
  font-weight: bold;
  line-height: 1.14;
  letter-spacing: 0.51px;
  color: #ffffff;
  margin-top: 40px;
  @media (max-width: 1300px) and (min-width: 1025px) {
    width: 473px;
  }
  @media (max-width: 1767px) and (min-width: 1025px) {
    margin-top: 0;
  }
  @media only screen and (max-width: 1024px) {
    padding-right: 100px;
    font-size: 40px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
  @media only screen and (max-width: 767px) {
    padding-right: 0;
    text-align: center;
  }
  @media (max-width: 1025px) and (min-width: 768px) {
    padding-right: 377px;
  }
  @media only screen and (max-width: 400px) {
    margin-top: 150px;
  }
  @media (max-width: 767px) and (min-width: 400px) {
    margin-top: 275px;
  }
}

.landing_intro_text_content {
  width: 700px;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: 0.13px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  margin-top: 40px;
  margin-bottom: 40px;
  @media (max-width: 1300px) and (min-width: 1025px) {
    width: 473px !important;
  }
  @media only screen and (max-width: 1024px) {
    padding-right: 166px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
  @media only screen and (max-width: 767px) {
    padding-right: 15px;
    font-size: 16px;
    text-align: center;
    margin-top: 50px;
  }
  @media (max-width: 1025px) and (min-width: 768px) {
    padding-right: 377px;
  }
}
.landing_download_button_div {
  display: flex;
}
.landing_intro_text_download {
  border-top: 1px solid aliceblue;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #ffffff;
  padding: 20px;
  margin-right: 20px;
  text-align: center;
}
.playstore_button {
  border-radius: 8px !important;
  width: 208px;
  height: 50px;
  background: linear-gradient(
    to top,
    #ab2814 1%,
    #ec6338 15%,
    #f39149
  ) !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.4px;
  color: #ffffff !important;
  box-shadow: none !important;
  margin-right: 20px !important;
  @media only screen and (max-width: 767px) {
    width: 140px;
    padding: 0 !important;
    font-size: 14px !important;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 11px !important;
    width: 140px;
  }
  @media only screen and (min-width: 320px) and (max-width: 568px) {
    padding-bottom: 25px;
  }
}
.store_section {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
}
.store_section_img_container {
  position: relative;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.store_section_back_img {
  position: absolute;
  left: -171px;
  top: -68px;
  z-index: 2;
  @media (min-width: 1024px) and (max-width: 1400px) {
    height: 600px;
  }
  @media only screen and (max-width: 1024px) {
    padding-right: 166px;
    left: -400px;
    width: 930px;
  }
  // margin-left: -168px;
  // margin-top: -70px;
}
.store_section_img_1 {
  // margin-left: -584px;
  // margin-top: -230px;
  position: absolute;
  left: 30px;
  top: 80px;
  z-index: 3;
  @media (min-width: 1024px) and (max-width: 1400px) {
    width: 150px;
  }
  @media only screen and (max-width: 1024px) {
    width: 150px;
  }
}

.store_section_img_2 {
  position: absolute;
  top: 268px;
  left: 166px;
  z-index: 4;
  // margin-left: -134px;
  // margin-top: 155px;
  @media (min-width: 1024px) and (max-width: 1400px) {
    top: 175px;
    left: 126px;
    width: 150px;
  }
  @media only screen and (max-width: 1024px) {
    width: 150px;
    top: 280px;
    left: 27px;
  }
}
.store_section_img_3 {
  position: absolute;
  top: 151px;
  left: 367px;
  z-index: 5;
  @media (min-width: 1024px) and (max-width: 1400px) {
    top: 130px;
    left: 236px;
    width: 150px;
  }
  @media only screen and (max-width: 1024px) {
    top: 167px;
    left: 100px;
    width: 150px;
  }
  // margin-left: -99px;
  // margin-top: -96px;
}
.store_section_dashed_background {
  position: absolute;
  top: 233px;
  left: 258px;
  @media (min-width: 1024px) and (max-width: 1400px) {
    width: 150px;
  }
  @media only screen and (max-width: 1024px) {
    top: 42px;
    left: -82px;
  }
}
.store_section_text_container {
  flex: 0 0 50%;
  padding-top: 130px;
  @media only screen and (max-width: 1400px) {
    padding-left: 200px;
  }
  @media only screen and (max-width: 767px) {
    display: none;
  }
}
.offer_section {
  .store_section_text_heading {
    @media only screen and (max-width: 1400px) {
      margin-right: 400px;
      font-size: 30px;
    }
    @media only screen and (max-width: 1100px) {
      padding: 0;
      padding-left: 0px;
      padding-right: 370px;
    }
  }
}

.store_section_text_heading {
  width: 645px;
  font-family: "Open Sans", sans-serif;
  /* height: 114px; */
  font-size: 35px;
  font-weight: bold;
  line-height: 1.14;
  letter-spacing: 0.51px;
  color: #ffffff;
  margin-top: 40px;
  @media (min-width: 768px) and (max-width: 1100px) {
    padding: 0px 150px;
  }
}

.offer_section {
  .store_section_text_content {
    @media only screen and (max-width: 1400px) {
      width: 400px;
      font-size: 16px;
    }
    @media only screen and (max-width: 1100px) {
      width: 100%;
      padding-left: 0;
      padding-right: 470px;
    }
  }
}

.store_section_text_content {
  width: 551px;
  /* height: 96px; */
  font-size: 18px;
  line-height: 1.6;
  letter-spacing: 0.13px;
  /* color: #ffffff; */
  font-family: "Open sans", sans-serif;
  margin-top: 40px;
  margin-bottom: 40px;
  @media only screen and (max-width: 1100px) {
    padding: 0 150px;
    padding-right: 180px;
    width: 100%;
  }
}

.phones_group_image {
  position: absolute;
  right: -187px;
  top: -49px;
  @media only screen and (max-width: 1024px) {
    right: 0px;
    top: 150px;
    width: 496px;
  }
  @media only screen and (max-width: 767px) {
    top: 96px;
  }
}

.offer_section {
  display: flex;
  justify-content: space-between;
  margin-top: 126px;
  padding-left: 93px;
  height: 600px;
  background-color: #f4fbff;
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}
.offer_section_backg {
  left: -239px;
  position: absolute;
  z-index: 5;

  @media only screen and (max-width: 1024px) {
    left: -340px;
    height: auto;
    top: 20px;
    width: 600px;
  }
}

.offer_section_img_container {
  position: relative;
  margin-right: 376px;
  margin-top: -70px;
}
.offer_section_nature {
  position: absolute;
  top: 330px;
  left: 30px;
  z-index: 10;
  @media only screen and (max-width: 1400px) {
    top: 263px;
    left: -102px;
  }
  @media only screen and (max-width: 1024px) {
    left: -176px;
    top: 280px;
    width: 160px;
  }
}
.offer_section_mega_sale {
  position: absolute;
  left: -344px;
  top: 440px;
  z-index: 10;
  @media only screen and (max-width: 1024px) {
    left: -451px;
    top: 354px;
  }
}
.offer_section_sale {
  position: absolute;
  top: 156px;
  left: 149px;
  z-index: 10;
  @media only screen and (max-width: 1400px) {
    top: 118px;
    left: 31px;
  }
  @media only screen and (max-width: 1024px) {
    left: -185px;
    top: 130px;
    width: 89px;
  }
}
.offer_section_jacket {
  position: absolute;
  top: 106px;
  left: -265px;
  z-index: 6;
  @media only screen and (max-width: 1400px) {
    width: 250px;
  }
  @media only screen and (max-width: 1024px) {
    left: -341px;
    top: 158px;
    width: 150px;
  }
}
.offer_section_shoes {
  position: absolute;
  top: 321px;
  left: -141px;
  z-index: 20;
  @media only screen and (max-width: 1400px) {
    top: 242px;
    left: -212px;
    height: 250px;
  }
  @media only screen and (max-width: 1024px) {
    left: -300px;
    top: 253px;
    width: 140px;
    height: 160px;
  }
}
.offer_section_dashed_backg {
  position: absolute;
  top: 252px;
  left: -400px;
  @media only screen and (max-width: 1024px) {
    top: 161px;
    left: -380px;
  }
}
.offer_section_text {
  margin-top: 160px;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 0;
  }
}
.card_section {
  margin-top: -70px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}
.card_section_images {
  position: relative;
}
.card_background {
  position: absolute;
  left: -284px;
  z-index: 8;
  @media only screen and (max-width: 1300px) {
    left: -220px;
    width: 400px;
    top: 80px;
  }
}
.loyality_card {
  position: absolute;
  top: 160px;
  left: 60px;
  z-index: 10;
  @media only screen and (max-width: 1300px) {
    top: 160px;
    left: 60px;
    width: 160px;
  }
}
.cad_dashed_backg {
  margin-left: -114px;
  margin-top: -20px;

  @media only screen and (max-width: 1300px) {
    margin-left: -26px;
    margin-top: 50px;
    width: 150px;
  }
}
.card_dashed_background_second {
  position: absolute;
  top: 320px;
  left: 190px;
  @media only screen and (max-width: 1300px) {
    top: 242px;
    left: 10px;
    width: 155px;
  }
}
.card_section_text {
  padding-top: 190px;
  @media only screen and (max-width: 1100px) {
    padding-top: 125px;
    button {
      margin-left: 148px !important;
    }
  }
}

.arrow_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  .button_style {
    background: none;
    border: none;
    color: inherit;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    :hover {
      cursor: pointer;
    }
  }
}
.about_section {
  width: 100%;
  // margin-top: 300px;
  // background-image: linear-gradient(69deg, #3b58e0 0%, #6bb5fc 100%);
  background-color: #f9e2be;
  max-height: 560px;
  padding: 60px 80px;
  @media only screen and (max-width: 1024px) {
    padding: 40px 60px;
  }
  @media only screen and (max-width: 767px) {
    padding: 30px 20px;
  }
  position: relative;
}
.about_card {
  background-color: aliceblue;
  display: flex;
  justify-content: space-around;
  padding: 35px 0;
  // box-shadow: 0 0 45px 0 rgba(7, 12, 69, 0.42);
  background-color: #ffffff;
  max-width: 1073px;
  // max-height: 242px;
  // margin: 0 auto;
  border-radius: 8px;
  padding-right: 20px;
  @media only screen and (max-width: 767px) {
    // padding-bottom: 190px !important;
    // padding-top: 100px;
  }

  @media only screen and (max-width: 1024px) {
    padding-right: 20px;
    padding-left: 20px;
    // padding-bottom: 100px;
    flex-direction: column;
  }
}
.about_image_container {
  position: relative;
  margin: 0 60px;
  @media only screen and (max-width: 767px) {
    margin: 10px 10px;
  }
  @media only screen and (max-width: 1024px) {
    margin: 10px 10px;
  }
}
.about_photo {
  position: absolute;
  left: -25px;
  @media only screen and (max-width: 767px) {
    left: -8px;
    top: 0;
  }
  @media only screen and (max-width: 768px) {
    left: 40px !important;
  }
  @media only screen and (max-width: 1024px) {
    left: 120px;
  }
}
.about_text {
  max-width: 770px;
  // height: 90px;
}
.about_text_content {
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  text-align: left;
  color: #333333;
  @media only screen and (max-width: 1400px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 10px;
  }
}
.about_person_intro {
  margin-top: 20px;
  /* height: 22px; */
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: 0.16px;
  /* text-align: center; */
  color: #f07d41;
  @media only screen and (max-width: 1024px) {
    font-size: 12px;
  }
}
.about_us_heading {
  /* width: 682px; */
  font-family: Montserrat-Bold;
  font-size: 36px;
  line-height: 1;
  text-align: center;
  margin-bottom: 40px;
  @media only screen and (max-width: 1024px) {
    font-size: 28px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
.about_arrow_left {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.about_arrow_right {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}
.business_section {
  margin: 60px 40px 40px 120px;
  position: relative;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}
.business_section_header {
  width: 850px;
  height: 46px;
  font-family: "Open Sans", sans-serif;
  /* font-family: OpenSans; */
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.36px;
  color: #333333;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
}
.business_section_text {
  font-family: "Open sans", sans-serif;
  width: 880px;
  height: 64px;
  /* font-family: OpenSans; */
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.11px;
  color: #333333;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding-right: 100px;
  }
}

.buisness_card {
  width: 566px;
  // height: 334px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 45px 0 rgba(7, 12, 69, 0.19);
  background-color: #ffffff;
  padding: 10px;
  @media only screen and (max-width: 1024px) {
    width: 340px;
  }
}
.position_card {
  position: absolute;
  z-index: 15;
  @media only screen and (max-width: 767px) {
    height: 580px !important;
    position: unset !important;
    width: 88% !important;
    margin: 0 auto !important;
  }
}

.buisness_card1 {
  @media only screen and (max-width: 1024px) {
    left: -96px;
  }
  @media only screen and (max-width: 767px) {
    top: 400px;
    left: 12px;
  }
}
.buisness_card2 {
  @media only screen and (max-width: 1024px) {
    right: -20px;
  }
  @media only screen and (max-width: 767px) {
    right: 8px;
  }
  //   @media only screen and (max-width: 767px) {
  //     top: 400px;
  //     left: 12px;
  //   }
}

.business_card_logo {
  margin: 20px 0px 20px 20px;
}
.buisness_card_heading {
  font-family: "Open Sans", sans-serif;
  margin: 20px 0px 20px 20px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: 0.25px;
  color: #f07d41;
}

.buisness_card_text {
  margin: 20px 0px 20px 20px;
  width: 509px;
  height: auto;
  // height: 104px;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.11px;
  color: #333333;
  .padding_5 {
    padding-left: 5%;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding-right: 20px;
  }
}
.buisness_cards_container {
  // margin-top: 100px;
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}
.business_backgound_two {
  position: absolute;
  left: -602px;
  top: 30px;
  z-index: 5;
}
.business_backgound_one {
  position: absolute;
  top: -150px;
  right: -602px;
  z-index: 5;
  @media only screen and (max-width: 1024px) {
    height: 434px;
    top: -5px;
    right: -290px;
  }
}
.buisness_dashed_background_first {
  position: absolute;
  top: 187px;
  left: -169px;
}
.buisness_dashed_background_two {
  position: absolute;
  top: -58px;
  right: -102px;
}
.about_dashed_background_third {
  top: 300px;
  right: -50px;
  position: absolute;
}
.form_accordian_container {
  display: grid;
  grid-template-columns: repeat(13,1fr);
  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
  // @media (min-width: 769px) and (max-width: 1024px) {
  //   padding-left: 10%;
  // }

  @media only screen and (max-width: 767px) {
    margin-left: 0;
  }
  @media only screen and (max-width: 767px) {
    margin-left: 0;
  }
  .landing_accordian_container {
    grid-column-start: 8;
    grid-column-end: 14;
  }
}

.accordian_heading {
  h1 {
    font-family: Montserrat-Bold;
    font-size: 27px;
    margin-bottom: 40px;
  }
}
.form_accordian_container_header {
  font-family: Montserrat-Bold;
  font-size: 36px;
  line-height: 1;
  color: #dd3978;
  margin-bottom: 40px;
  @media only screen and (max-width: 1024px) {
    font-size: 28px;
    line-height: 1.2;
}
@media only screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: center;
}
  
}

.news_letter {
  text-align: center;
}
.news_letter_heading {
  font-family: Montserrat-Bold;
  font-size: 30px;
  text-transform: uppercase;
  color: #dd3978;
  line-height: 1;
  margin-bottom: 30px;
  // @media only screen and (max-width: 1024px) {
  //   color: #333333;
  //   height: auto;
  //   padding: 30px 40px 20px 45px;
  // }
  // padding: 30px 40px 80px 45px;
  // @media only screen and (max-width: 767px) {
  //   font-size: 20px;
  // }
}
.news_checkbox_content {
  font-family: Montserrat-Regular!important;
  font-size: 14px!important;
  color: #000000 !important;
  text-align: justify;
  margin-left: 20px;
}

.news_checkbox_content_landing {
  max-width: 70%;
  margin: auto;
  margin-bottom: 40px;
}

.footer_link {
  width: 152px;
  height: 32px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.8px;
  color: #ffffff;
  margin: 20px;
}

.footer_link_container {
  // height: 364px;
  background-image: linear-gradient(76deg, #25378d 0%, #1a71a5 100%);
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
}

.footer_copyright_contiainer {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background: #25378d;
  // height: 60px;
}
.all_rights {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.51px;
  color: #ffffff;
}

.footer_button {
  width: 322px;
  display: block !important;
  margin: 20px 20px !important;
  @media only screen and (max-width: 1024px) {
    width: 165px;
  }
}

.form_accordian_container_main {
  // padding-top: 400px;
  //margin-top: 240px;
 // padding-bottom: 30px;
  padding: 60px 80px;
  background: #FAEBC1;
  @media only screen and (max-width: 1024px) {
    padding: 40px 60px;
  }
  @media only screen and (max-width: 767px) {
      padding: 30px 20px;
  }
}
.carousel_container {
  position: relative;
  width: calc(55vw);
  @media only screen and (max-width: 767px) {
    width: calc(80vw);
  }
  .carousel .slide {
    background: none;
  }
  .carousel.carousel-slider {
    position: inherit;
  }
  .control-dots {
    display: none;
    width: auto;
    height: 18px;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
    background-color: #ffffff;
    left: 45%;
    top: 110%;
    padding-bottom: 22px;
    @media only screen and (max-width: 767px) {
      left: 45%;
    }
  }
  .carousel .control-dots .dot {
    background: #d3d2d2;
    width: 10px;
    height: 10px;
  }
  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
    background-color: #f39149;
    outline: none;
  }
  .carousel .slider-wrapper {
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }
  .carousel .slide img {
    @media only screen and (max-width: 1024px) {
      width: 130px;
    }
    @media only screen and (max-width: 767px) {
      // width: 60px;
      width: 130px;
    }
  }
}
.about_dashed_backgroung {
  width: 120px !important;
  @media only screen and (max-width: 1024px) {
    width: 130px !important;
  }
}
.landing_accordian_box_style {
  border-radius: 8px;
  // box-shadow: 0 0 45px 0 rgba(7, 12, 69, 0.19);
  background-color: #ffffff;
  // width: 567px;
  margin: 20px 0;
  &:first-child {
    margin: unset;
    margin-bottom: 20px;
  }
  // @media only screen and (max-width: 500px) {
  //   width: 290px;
  // }
  // @media (min-width: 500px) and (max-width: 767px) {
  //   width: 100%;
  // }
}
.landing_accordian .title {
  position: relative;
  padding-left: 25px !important;
  width: 100%;
  // height: 50px;
  border-radius: 8px;
  // box-shadow: 0 0 45px 0 rgba(7, 12, 69, 0.19);
  border: solid 1px #d5d5d5;
  // background-color: #ffffff;
  // background-image: linear-gradient(85deg, #3b58e0 0%, #6bb5fc 100%);
}
.landing_register_accordian_icon {
  position: absolute;
  right: 23px;
  top: 15px;
  font-size: 25px !important;
}

.landing_accordian_heading {
  width: 89%;
  // height: 47px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: 0.23px;
  color: #333333;
}

.frequently_answers {
  //height: 84px;
  font-family: Montserrat-Regular!important;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.09px;
  color: #333333;
  @media only screen and (max-width: 767px) {
    width: 100%;
    height: auto;
  }
}
.landing_city_container {
  position: relative;
  max-width: 49%;
  @media only screen and (min-width: 1024px) {
    max-width: 460px;
  }
  @media only screen and (max-width: 1100px) {
    padding-left: 143px;
  }
}
.goto_city {
  height: 50px;
  margin-right: 0px;
  position: absolute;
  left: 446px;
  top: 0;
  @media only screen and (min-width: 1025px) {
    left: 440px !important;
  }
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  // @media only screen and (max-width: 1024px) {
  //   left: 316px;
  // }
  @media (min-width: 768px) and (max-width: 1024px) {
    left: 370px;
  }
  &:disabled {
    background: #818181 none !important;
  }
}

.store_section_main_container {
  overflow: hidden;
}
.for_mobile_view_group {
  img {
    width: 380px;
  }
  @media only screen and (min-width: 767px) {
    display: none;
  }
  @media only screen and (min-width: 500px) {
    margin: 0 auto;
  }
}
.mobile_store_section_text_container {
  @media only screen and (min-width: 767px) {
    display: none;
  }
}
.mobile_store_section_text_heading {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.21px;
  text-align: center;
  color: #333333;
  padding-left: 20px;
  padding-right: 20px;
  @media only screen and (min-width: 767px) {
    padding-top: 20px;
    text-align: center;
  }
}
.mobile_store_section_text_content {
  width: 100%;
  // height: 76px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.08px;
  text-align: center;
  color: #333333;
  padding: 20px 20px;
  font-size: 16px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}
.mobile_offer_section {
  @media only screen and (min-width: 767px) {
    display: none;
  }
}
.business_section_mobile {
  padding-top: 30px;
}
.mobile_card_section {
  @media only screen and (min-width: 767px) {
    display: none;
  }
}

.landing_city_dropdown i {
  top: 15px;
  right: 18px;
}
.landing_city_container .selection {
  width: 94% !important;
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 220px !important;
  }
}
.news_leter_input {
  position: relative;
  margin: 20px auto;
  width: 70%;
  @media only screen and (max-width: 1024px) {
    width: 80%;
  }
  
  input {
    height: 55px;
    font-size: 22px;
    font-family: Montserrat-Regular!important;
    border-color: #dd3978!important;
    border-radius: 10px!important;
    &::placeholder {
      color: #8A8B8A!important;
    }
  }
}
.ladingFormNewsError {
  font-size: 16px!important;
  font-family: Montserrat-Regular!important;
  // top: 115px!important;
  top: 59px!important;
  @media only screen and (max-width: 1024px) {
    left: 75px !important;
  }
  @media only screen and (max-width: 768px) {
    left: 70px !important;
  }
}
.ladingFormNewsError-checkbox {
  font-size: 16px!important;
  font-family: Montserrat-Regular!important;
  top: 55px!important;
  @media only screen and (max-width: 1024px) {
    left: 38px !important;
    top: 75px!important;
  }
  @media only screen and (max-width: 768px) {
    left: 38px !important;
    top: 92px!important;
  }
}
.phones_group_image_mobile {
  top: 96px;
  position: absolute;
  width: 100%;
}
.news_letter_container {
  padding: 60px 80px;
  @media only screen and (max-width: 1024px) {
    padding: 40px 60px;
  }
  @media only screen and (max-width: 767px) {
      display: none;
      padding: 30px 20px;
  }
}
.mobile_offer_section_image {
  @media only screen and (min-width: 500px) {
    margin-left: 22%;
  }
}
.mobile_card_section_image {
  @media only screen and (min-width: 500px) {
    margin-left: 22%;
  }
}
// .landing_intro_text_heading {
//   background-image: url('../../assets/images/mobile-view-group-image.png');
//   height: 878px;
//   background-size: cover;
//   background-position: right -155px top -47px;
//   background-color: #4c67e6;
// }
