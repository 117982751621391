.offer_modal_header {
  background-repeat: no-repeat !important;
  background-position: center !important;
  // width: 100% !important;
  // height: 333px !important;
  margin-top: 30px !important;
  margin-bottom: 10px !important;
  padding-bottom: 20px !important;
  object-fit: contain !important;
  // border-radius: 8px !important;
  background-size: cover;

  text-align: center;
  img {
    width: 50%;
  }
}

.modal_close_btn {
  top: unset !important;
  right: 0 !important;
  color: black !important;
}

.modal_product_description {
  // width: 465px;
  height: auto;
  // height: 58px;
  object-fit: contain;
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: 0.19px;
  text-align: center;
  color: #333333;
  margin: 20px auto;
}

.product_desctrition_second {
  // width: 506px;
  height: auto;
  object-fit: contain;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: center;
  color: #333333;
}

.promo_valid_date {
  // width: 320px;
  height: 18px;
  object-fit: contain;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: 0.43px;
  text-align: center;
  color: #989898;
  margin: 0 auto;
}

.promo_modal_food {
  // width: max-content;
  display: inline-block;
  height: 18px;
  object-fit: contain;
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.8;
  letter-spacing: 0.36px;
  text-align: center;
  color: #000000;
  margin: 10px auto;
}

.promo_modal_shop {
  // width: 343px;
  height: auto;
  display: block;
  object-fit: contain;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: center;
  color: #333333;
  margin: 10px auto;
  // padding-left: 2vw;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-top: 15px;
}

.offer_modal_main_content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
