.reset_passord_header {
  border: none;
  font-size: 22px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14 !important;
  letter-spacing: 0.23px;
  text-align: center;
  color: #333333 !important;
  border: none !important;
  font-family: 'Open Sans', sans-serif !important;
}
.reset_password_email {
  width: 100%;
  margin-top: 23px;
}
.reset_password_text {
  width: 372px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.09px;
  color: #333333;
  margin: 0 auto;
  font-family: 'Open Sans', sans-serif;
}
.resetFormNewsError {
  position: absolute;
  top: 65px !important;
  left: 4px !important;
  color: red;
  font-family: 'Open Sans', sans-serif;
}
