form.ui.form input[type="text"] {
  border-radius: 8px;
  height: 50px;
  margin-bottom: 15px;
  margin-right: 10px;
  margin-left: 10px;
  border: solid 1px #d5d5d5;
}
form.ui.form input[type="number"] {
  border-radius: 8px;
  height: 50px;
  margin-bottom: 15px;
  margin-right: 10px;
  margin-left: 10px;
  border: solid 1px #d5d5d5;
  outline: none;
}
input[type="number"]:focus {
  border-color: #cdcdcd !important;
}

form.ui.form input[type="password"] {
  border-radius: 8px !important;
  height: 50px;
  margin-bottom: 15px;
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 50px;
  border: solid 1px #d5d5d5;
}
input[type="password"]:focus {
  border-color: #d5d5d5 !important;
}
form.ui.form input[type="email"] {
  border-radius: 8px;
  height: 50px;
  margin-bottom: 15px;
  margin-right: 10px;
  margin-left: 10px;
  border: solid 1px #d5d5d5;
}

form.ui.form input[type="text"]::-webkit-input-placeholder {
  height: 19px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.44px;
  color: #9e9e9e;
}
form.ui.form input[type="number"]::-webkit-input-placeholder {
  height: 19px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.44px;
  color: #9e9e9e;
}
.privacy_points {
  height: 19px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  color: #3a3a3a;
  margin: 10px 0 0 12px;
}

.legal_info {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.3px;
  color: #3a3a3a;
  margin-left: 12px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  // height: 41px;
  // margin-bottom: 25px !important;
}

.privacy_policy {
  font-weight: bold;
  color: #e45c34;
  text-decoration: underline;
  display: block;
}

.checkbox_points {
  // height: 57px;
  // font-size: 14px;
  // font-weight: normal;
  // font-stretch: normal;
  // font-style: normal;
  // line-height: 1.58;
  // letter-spacing: 0.96px;
  // color: #092853;
  margin: 20px 0;
  @media screen and (max-width: "768px") {
    margin: 30px 0;
  }
  position: relative;
  display: flex;
  align-items: center;
  label {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: 0.96px;
    color: #333333;
    cursor: pointer;
  }
}

.register_button {
  display: block !important;
  margin: 0 auto !important;
  width: 208px;
  height: 44px;
  border-radius: 8px !important;
  color: #ffffff !important;
  background: linear-gradient(
    to top,
    #ab2814 1%,
    #ec6338 15%,
    #f39149
  ) !important;
}

.register_button:hover {
  background: linear-gradient(
    to bottom,
    #ab2814 1%,
    #ec6338 15%,
    #f39149
  ) !important;
}

.checkbox_tick {
  width: 25px;
  height: 18px;
  border-radius: 4px;
  margin-right: 5px;
  border: solid 1px #092853;
  margin-top: 3px;
}

.signUpModal {
  .angle {
    position: absolute;
    right: 4px;
    top: 11px;
    color: #f39149;
    padding-left: 9px;
    border-left: 1px solid #d8d8d8;
    font-size: 30px !important;
    padding-top: 8px !important;
  }
}

.selection {
  border-radius: 8px !important;
  height: 50px;
  margin-right: 10px !important;
  margin-left: 10px;
  width: 96% !important;
  padding-top: 15px !important;
}

.calendar {
  color: #f39149 !important;
  font-size: 20px !important;
  border-left: 1px solid #d8d8d8 !important;
  height: 30px !important;
  margin-top: 13px !important;
  right: 4px !important;
}
.gender_dropdown {
  margin-bottom: 20px !important;
}
.error_message_sign_up {
  position: absolute;
  top: 52px;
  left: 15px;
  color: red;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  padding-right: 10px;
}
.city_field {
  top: 62px;
}
.conf_pass_sign_up {
  top: 66px;
  left: 30px;
}
.gender_dropdown {
  .listbox {
    border: solid 1px #d5d5d5;
  }
  .menu {
    border: solid 1px #d5d5d5;
  }
  .ui.selection.active.dropdown {
    border: solid 1px #d5d5d5;
  }
  .ui.selection.active.dropdown .menu {
    border: solid 1px #d5d5d5;
  }
  .ui.selection.dropdown:focus {
    border: solid 1px #d5d5d5;
  }
}
.user_reg_phone_container {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.date_container_user {
  .react-datepicker-wrapper {
    width: 320px;
  }
}
.calendar_image_user {
  position: absolute;
  pointer-events: none;
  left: 275px;
  top: 10px;
  border-left: 1px solid #d8d8d8;
  padding-left: 15px;
}
