.shop_detail_modal {
  max-width: 786px;
  height: auto;
}
.shop_modal_header {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding-right: 5px;
  align-items: center;
  .header_text {
    width: auto;
    // height: 30px;
    font-family: "Open Sans", sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.94px;
    color: #333333;
  }
}
.bonus_section {
  width: 343px;
  height: 134px;
  border-radius: 8px;
  box-shadow: 0 0 16px 0 rgba(17, 5, 149, 0.2);
  background-color: #ffffff;
  position: relative;
  padding-top: 16px;
  .bonus_heading {
    width: 125px;
    height: 16px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    color: #333333;
  }
  .bonus_points {
    display: inline-block;
    width: 154px;
    height: 68px;
    border-radius: 7px;
    background-image: linear-gradient(66deg, #3b58e0 0%, #6bb5fc 100%);
    padding-top: 10px;
    .bonus_points_number {
      // width: 85px;
      height: 24px;
      font-family: "Open Sans", sans-serif;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.09;
      letter-spacing: 0.66px;
      text-align: center;
      color: #ffffff;
      display: inline-block;
      .bonus_pt {
        font-size: 14px;
        letter-spacing: 0.42px;
        display: inline-block;
      }
    }
    .points_text {
      width: 100%;
      display: block;
      height: 17px;
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.91px;
      text-align: center;
      color: #ffffff;
    }
    .cashback_text {
      display: block;
      width: 100%;
      height: 17px;
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.91px;
      text-align: center;
      color: #ffffff;
    }
  }
  .equal_circle {
    position: absolute;
    left: 148px;
    top: 54px;
    display: inline-block;
    width: 48px;
    height: 48px;
    box-shadow: 0 0 16px 0 rgba(17, 5, 149, 0.2);
    background-color: #ffffff;
    border-radius: 50%;
    padding-top: 10px;
    .equal_operator {
      width: 20px;
      height: 24px;
      object-fit: contain;
      font-family: OpenSans;
      font-size: 33px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.73;
      letter-spacing: 0.99px;
      text-align: center;
      color: #446be5;
    }
  }
}
.bonus_bar_container {
  width: 343px;
  height: 202px;
  border-radius: 8px;
  box-shadow: 0 0 16px 0 rgba(17, 5, 149, 0.2);
  // margin-top: 30px;
  margin-bottom: 15px;
  padding: 30px 0;
  .bonus_bar_heading {
    width: 100%;
    text-align: start;
    padding: 0 10px;
    height: 16px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    color: #3a3a3a;
  }
  .bonus_bar_content {
    width: 100%;
    text-align: start;
    padding: 0 10px;
    height: 60px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.06px;
    color: #333333;
  }
}
.progress1circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #f5f5f5;
  display: inline-block;
}
.progress_filled {
  background-image: linear-gradient(66deg, #3b58e0 0%, #6bb5fc 100%);
}
.progress1line {
  width: 16px;
  height: 5px;
  background-color: #f5f5f5;
  display: inline-block;
  margin-bottom: 5px;
}
.lastcircle {
  width: 30px;
  height: 30px;
  position: absolute;
  top: -22px;
}
.progress_line_container {
  position: relative;
  width: 310px;
}
.shop_detail_column_container {
  display: flex;
  padding-top: 30px;
}
.history_transactional_column {
  width: 370px;
  margin-left: 30px;
  border-radius: 8px;
  box-shadow: 0 0 16px 0 rgba(17, 5, 149, 0.2);
  padding-right: 10px;
  @media only screen and (max-width: 768px) {
    width: 250px;
  }
}
.history_transaction_text {
  width: 200px;
  height: 16px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #424242;
  padding: 20px 10px;
}
.shop_detail_column_container ::-webkit-scrollbar {
  width: 5px;
}

// body::-webkit-scrollbar-thumb {
//   background-color: #fc4be1 !important;
//   border-radius: 2px !important;
// }
// body::-webkit-scrollbar {
//   width: 20px !important;
// }

// body::-webkit-scrollbar-track {
//   background-color: #202020 !important;
//   border-radius: 2px !important;
// }
.transaction_data_points_new {
  width: auto !important;
}
.go_to_shop_fidlity {
  background: none;
  border: none;
  outline: none;
  color: #ffffff;
  cursor: pointer;
}

.bonus_cards_main_container {
  max-height: 300px;
  overflow-y: scroll;
  padding: 10px;
  margin-top: 20px;
}

.fidelity_modal {
  min-width: 200px im !important;
  max-width: 240px !important;
}
