.home_carousae_container {
  position: relative;

  .corousal_left_button {
    position: absolute;
    top: 50%;
    left: 30px;
    // Disable deafult button property
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: #ffffff;
    margin: 0 10px;

    :hover {
      cursor: pointer;
    }

    @media (min-width: 1001px) and (max-width: 1024px) {
      left: 54px;
    }
  }

  .corousal_right_button {
    position: absolute;
    top: 50%;
    right: 20px;
    // Disable deafult button property

    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: #ffffff;
    margin: 0 10px;

    :hover {
      cursor: pointer;
    }

    // @media only screen and (max-width: 1700px) {
    //   top: 50%;
    //   right: 10%;
    // }
  }

  .first_photo {
    // background-image: url('../../assets/images/home-header-background.png');

    // background-size: contain;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 31.7%; /* (img-height / img-width * container-width) */
    /* (853 / 1280 * 100) */
    @media only screen and (max-width: 1024px) {
      width: 100%;
      height: 513px;
      padding-top: 0px;
      background-size: cover;
    }
  }

  .carousel .control-dots {
    left: 14%;
    text-align: start;
    @media only screen and (max-width: 1700px) {
      left: 20%;
    }
  }

  .carousel .control-dots .dot {
    opacity: 1;
    width: 11px;
    height: 11px;
  }

  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
    opacity: 1;
    background-color: #f39149;
  }

  .text_container {
    position: absolute;
    top: 41%;
    left: 13%;
    @media only screen and (max-width: 1700px) {
      top: 38%;
      left: 10%;
    }
  }

  .carousal_text_heading {
    width: 646px;
    height: auto;
    font-family: "Open Sans", sans-serif;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: 0.41px;
    color: #ffffff;
    text-align: left;
    @media only screen and (max-width: 768px) {
      font-size: 32px;
      padding-right: 151px;
    }
  }

  .carousal_text_content {
    width: 564px;
    height: 75px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: 0.7px;
    color: #ffffff;
    text-align: left;
    padding-top: 10px;
  }

  .homePageDiscover {
    position: absolute;
    top: 80%;
    left: 13%;
    width: 210px !important;
    @media only screen and (max-width: 1700px) {
      left: 20%;
    }
  }
}

// .shopping_center {
//   grid-row-start: 1;
//   grid-row-end: 2;
//   grid-column-start: 1;
//   grid-column-end: 19;
//   padding-left: 30px;
//   padding-right: 30px;
//   padding-top: 20px;
//   @media (min-width: 768px) and (max-width: 900px) {
//     padding-left: 30px;
//   }

//   @media (min-width: 901px) and (max-width: 1024px) {
//     padding-left: 18px;
//     padding-right: 10px;
//   }
// }

.shopping_center_details_container::before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(9 / 16 * 100%);
  position: absolute;
  left: -20px;
}
.shopping_center_details_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  @media only screen and (max-width: 1400px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
  grid-gap: 30px;

  // display: flex;
  // flex-wrap: wrap;

  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  // @media only screen and (max-width: 1024px) {
  //   grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
  // }
  // @media only screen and (max-width: 830px) {
  //   grid-template-columns: 1fr 1fr 1fr;
  //   grid-gap: 20px;
  // }
  // @media (min-width: 1025px) and (max-width: 1300px) {
  //   grid-template-columns: 1fr 1fr 1fr 1fr;
  // }
  // grid-gap: 30px;
}

.malls_and_shop_container {
  // display: grid;
  grid-template-columns: repeat(18, 1fr);
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 19;
}

// .shopping_centre_name {
//   width: 100%;
//   height: auto;
//   font-family: "Open Sans", sans-serif;
//   font-weight: bold;
//   font-size: 18px;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.17;
//   letter-spacing: 0.67px;
//   color: #333333;
//   padding: 10px;
//   padding-bottom: 0;
// @media only screen and (max-width: 1000px) {
//   font-size: 16px;
// }
//   @media only screen and (max-width: 768px) {
//     width: 100%;
//     height: auto;
//     font-family: "Open Sans", sans-serif;
//     font-size: 14px;
//     font-weight: bold;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.36;
//     letter-spacing: 0.08px;
//     color: #333333;
//   }
//   @media only screen and (max-width: 768px) {
//     margin-top: 26px;
//     margin-bottom: 15px;
//   }
// }

// .shopping_centre_address {
//   margin-left: 10px;
//   font-family: "Open Sans", sans-serif;
//   font-size: 14px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: 0.09px;
//   color: #616161;
//   @media only screen and (max-width: 1000px) {
//     font-size: 12px;
//   }
// }

// .shopping_center_address_container {
//   width: 100%;
//   display: flex;
//   padding: 0 50px 0 10px;
//   @media only screen and (max-width: 780px) {
//     padding: 0 0 0 10px;
//   }

//   @media only screen and (max-width: 768px) {
//     align-items: flex-start;
//     img {
//       width: 18px;
//     }
//   }
// }

.shopping_center_heading {
  width: 343px;
  height: 33px;
  font-family: "Open Sans";
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.03px;
  color: #333333;
  margin-top: 60px;
  margin-bottom: 25px;
  // margin-left: 20px;
}

.phone_container {
  //   width: calc(100vw - 240px);
  //   height: 393px;
  //   object-fit: contain;
  background: url("../../assets/images/home-phones.png") no-repeat center /
    cover;

  //   background-size: contain;
  //   background-repeat: no-repeat;
  width: 100%;
  height: 0;
  position: relative;
  @media only screen and (max-width: 780px) {
    height: 350px;
    border-radius: 45px !important;
  }
  padding-top: 33.87%; /* (img-height / img-width * container-width) */
  /* (853 / 1280 * 100) */
}

.home_phone_image_container {
  // max-width: 1160px;
  // width: 100%;
  // margin: 0 auto;
  // margin-top: 20px;
  // margin-bottom: 80px;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 5;
  grid-column-end: 15;
  margin: 80px 0;
  @media only screen and (max-width: 1024px) {
    grid-column-start: 2;
    grid-column-end: 18;
  }
}
.home_page_content {
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  grid-template-rows: auto auto;
  padding: 0 35px 0 40px;
  @media only screen and (max-width: 1024px) {
    // padding: 0 40px 0 35px;
    padding: 0 30px 0 60px;
  }
  @media only screen and (max-width: 1000px) {
    padding: 0 30px 0 40px;
  }
}
.phone_container_heading {
  position: absolute;
  top: 28%;
  left: 10%;
  width: 447px;
  height: 74px;
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: 0.31px;
  color: #ffffff;
  @media only screen and (max-width: 780px) {
    font-size: 20px;
    padding-right: 132px;
  }
}

.phone_container_content {
  position: absolute;
  top: 50%;
  left: 10%;
  width: 447px;
  height: 96px;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.11px;
  color: #ffffff;
  @media only screen and (max-width: 780px) {
    padding-right: 140px;
  }
  @media only screen and (max-width: 768px) {
    padding-right: 180px;
  }
}

.home_page_play_store {
  position: absolute;
  top: 75%;
  left: 10%;
  @media only screen and (max-width: 780px) {
    height: 39px !important;
    margin-top: 16px !important;
  }
}

.home_page_apple {
  position: absolute;
  top: 75%;
  left: 32%;
  @media only screen and (max-width: 780px) {
    left: 34%;
  }
  @media only screen and (max-width: 780px) {
    height: 39px !important;
    margin-top: 16px !important;
  }
}

.home_search_container {
  position: absolute;
  top: 70px;
  left: 0px;
  right: 0;
  // width: calc(100vw - 225px);
  z-index: 3;
  @media only screen and (max-width: 1024px) {
    // width: calc(100vw - 70px);
    top: 65px;
  }
}

.home_search_container_active {
  position: fixed;
  left: 0;
  right: 0;
  top: 80px;
  width: 100%;
  // padding: 0 80px;
  @media only screen and (max-width: 1024px) {
    top: 70px;
  }
}

.profile_logout_card {
  position: absolute;
  top: 45px;
  z-index: 3;
  right: -10px;
  width: 208px;
  height: 120px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
}

.profile_logout_card_active {
  // position: fixed;
  z-index: 4;
  // top: 80px;
  // right: 96px;
  // @media only screen and (max-width: 768px) {
  //   top: 80px;
  //   right: 25px;
  // }
}

.home_logout_button {
  position: absolute;
  left: 11px;
  bottom: 10px;
  background: none;
  color: #e60000 !important;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  color: #ffffff;
  outline: none;
  margin: 0;
  width: 185px;
  height: 45px;
  border-radius: 8px;
  border: solid 2px #e60000;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: center;
}

.view_profile_text {
  width: 182px;
  height: 19px;
  font-family: "Open Sans";
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: center;
  color: #f18846;
  margin: 15px 10px 10px 10px;
  cursor: pointer;
}

.triangle_up {
  position: absolute;
  top: -20px;
  right: 15px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid #ffffff;
}

.notification_card {
  position: absolute;
  padding-bottom: 4px;
  padding-top: 4px;
  // top: 100px;
  top: 45px;
  z-index: 3;
  right: -15px;
  width: auto;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  // @media only screen and (max-width: 1400px) {
  //   right: 140px;
  // }
  .triangle_up {
    right: 23px !important;
  }

  .notifiacaion_item {
    width: 348px;
    height: 95px;
    // border-radius: 8px;
    background-color: #f4f6fb;
    padding: 20px 10px;
    display: flex;
    justify-content: space-around;

    .notication_image_container {
      position: relative;
      width: 41px;
      height: 41px;
      border-radius: 27.7px;
      border-style: solid;
      border-width: 1.4px;
      border-image-source: linear-gradient(45deg, #3b58e0 0%, #6bb5fc);
      border-image-slice: 1;
      background-image: linear-gradient(to bottom, #e6ecfb, #e6ecfb),
        linear-gradient(45deg, #3b58e0 0%, #6bb5fc);
      background-origin: border-box;
      background-clip: content-box, border-box;

      .notification_image_type {
        position: absolute;
        left: 5px;
        top: 5px;
      }
    }

    .notification_heading {
      width: 244px;
      height: 19px;
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.24px;
      color: #333333;
    }

    .notification_text {
      width: 252px;
      height: 33px;
      font-family: "Open Sans", sans-serif;
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      color: #333333;
    }
  }
}

.notification_card_active {
  // position: fixed;
  // right: 142px;
  // top: 79px;
  z-index: 5;
}

// .shoppping_centre_card {
//   flex: 0 1 30%;
//   @media only screen and (max-width: 1024px) {
//     flex-basis: 50%;
//   }
//   @media only screen and (max-width: 768px) {
//     flex-basis: 100%;
//     height: 150px;
//   }

//   .shoppping_centre_card_image img {
//     width: 100%;
//     @media only screen and (max-width: 1024px) {
//       height: 144px;
//     }
//     @media only screen and (min-width: 1100px) {
//       height: 230px;
//     }
//     height: 230px;
//     border-top-left-radius: 8px;
//     border-top-right-radius: 8px;
//     @media only screen and (max-width: 768px) {
//       width: 134px;
//       height: 150px;
//       border-bottom-left-radius: 8px;
//       border-top-right-radius: 0px;
//     }
//   }

//   @media only screen and (max-width: 375px) {
//     flex: 0 0 100%;
//   }
// }

.address_detail_name {
  width: max-content;
  height: auto;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.05px;
  text-align: right;
  color: #333333;
}

.address_detail_value {
  width: 100%;
  height: auto;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.07px;
  color: #333333;
  margin-left: 20px;
}

// .address_key-value {
//   display: flex;
// }
.section_4_address_container {
  display: flex;
}

.address_key_cotainer {
  text-align: -webkit-right;
}

// .heart_button {
//   background: none;
//   border: none;
//   outline: none;
//   cursor: pointer;
// }

.home_page_no_data {
  margin: 300px auto;
  width: max-content;
  font-size: 30px;
  font-family: "Open Sans", sans-serif;
}

.no_store_image_container {
  margin: 200px auto;
  max-width: 100%;
  grid-column-start: 7;
  grid-column-end: 14;
  grid-row-start: 1;
  grid-row-end: 2;
  text-align: center;

  img {
    // margin-left: 25%;
    max-width: 100%;
    // @media only screen and (max-width: 768px) {
    //   margin-left: 0;
    // }
  }
}
.home_page_loader {
  grid-column-start: 9;
  grid-column-end: 10;
  margin: 150px 0 !important;
}

.no_store_found {
  margin-top: 20px;
  width: 100%;
  height: auto;
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: 0.31px;
  color: #333333;
  text-align: center;
  @media only screen and (max-width: 768px) {
    font-size: 15px;
  }
}
.new_home_phone_image_container {
  grid-column-start: 3;
  grid-column-end: 17;
  display: grid;
  grid-template-columns: 1fr 1fr;
  // height: 240px;
  // background: yellow;
  border-radius: 8px;
  box-shadow: 0 0 24px 4px rgba(0, 0, 0, 0.34);
  background-image: linear-gradient(61deg, #3b58e0 0%, #6bb5fc 100%);
  margin: 80px 0;
  overflow: hidden;
  @media only screen and (max-width: 1024px) {
    grid-column-start: 2;
    grid-column-end: 18;
  }
  @media only screen and (min-width: 1400px) {
    grid-column-start: 5;
    grid-column-end: 15;
  }
  @media only screen and (min-width: 1600px) {
    grid-column-start: 6;
    grid-column-end: 14;
  }
}
.new_phone_image {
  height: 300px;
}
.new_phone_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto 50px;
  padding: 60px 20px;
}
.new_phone_container_heading {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 4;
}
.new_phone_container_content {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 4;
}
.home_page_play_store_button {
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 2;
}
.home_page_apple_store {
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 2;
  grid-column-end: 4;
}

.home_page_phone_conatainer {
  align-self: end;
}
.new_phone_container_heading {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: 0.31px;
  color: #ffffff;
  @media only screen and (max-width: 1024px) {
    font-size: 20px;
  }
}
.new_phone_container_content {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.11px;
  color: #ffffff;
  @media only screen and (max-width: 1024px) {
    font-size: 14px;
  }
}
