.registerModalButton {
  border-radius: 8px !important;
  width: 193px !important;
  height: 44px;
  background: linear-gradient(
    to top,
    #ab2814 1%,
    #ec6338 15%,
    #f39149
  ) !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.4px;
  color: #ffffff !important;
  box-shadow: none !important;
}

