.promo_modal_header {
  /* background-size: cover !important; */
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 100% !important;
  height: 333px !important;
  margin-top: 30px !important;
  margin-bottom: 10px !important;
  padding-bottom: 20px !important;
  object-fit: contain !important;
  border-radius: 8px !important;
}
.modal_product_description {
  // width: 465px;
  height: auto;
  // height: 58px;
  object-fit: contain;
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: 0.19px;
  text-align: center;
  color: #333333;
  margin: 20px auto;

  // padding-left: 2vw;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-top: 15px;
}

.product_desctrition_second {
  // width: 506px;
  height: auto;
  object-fit: contain;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: center;
  color: #333333;
  // padding-left: 2vw;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-top: 15px;
}
.promo_valid_date {
  // width: 320px;
  height: 18px;
  object-fit: contain;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: 0.43px;
  text-align: center;
  color: #989898;
  margin: 0 auto;
}
.promo_modal_food {
  // width: max-content;
  display: inline-block;
  height: 18px;
  object-fit: contain;
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.8;
  letter-spacing: 0.36px;
  text-align: center;
  color: #000000;
  margin: 10px auto;
}
.promo_modal_shop {
  // width: 343px;
  height: auto !important;
  // display: block;
  object-fit: contain;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: center;
  color: #333333;
  margin: 10px auto;
  // padding-left: 2vw;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-top: 15px;
}

.offer_text_container {
  display: flex;
  flex-direction: row;
  height: 100%;
  .promo_image_div {
    width: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 30vw;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    @media only screen and (max-width: 1024px) {
      min-height: 40vw;
    }
  }
}

.promo_modal_main_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 20px;
  // max-height: 25vw;
  // @media only screen and (max-width: 1024px) {
  //   max-height: 40vw;
  // }
}
.offer_modal_image {
  width: 50%;
  height: 25vw;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  @media only screen and (max-width: 1600px) {
    height: 40vw;
  }
  @media only screen and (max-width: 1000px) {
    height: 45vw;
  }
  @media only screen and (max-width: 900px) {
    height: 55vw;
  }
}
.promo_modal_button {
  width: 200px !important;
}
