.merchant-container {
    .pink {
        color: #dd3978;
      }
    padding: 0 100px;
    @media only screen and (max-width: 1024px) {
        padding: 0 40px;
    }
    @media only screen and (max-width: 767px) {
        padding: 20px;
    }
    &-block1 {
        padding-top: 60px;
        padding-bottom: 60px;
        @media only screen and (max-width: 1024px) {
            padding-top: 60px;
            padding-bottom: 60px;
        }
        @media only screen and (max-width: 767px) {
            padding-top: 20px;
            padding-bottom: 30px;
        }
    }
    .yellow-button {
        background-color: #eaa936;
        width: unset;
        padding: 0 30px;
        @media only screen and (max-width: 767px) {
            padding: 0 10px;
        }
    }
    .merchant-content {
        padding: 60px 55px 80px 60px;
        margin-bottom: 60px;
        @media only screen and (max-width: 1024px) {
            padding: 40px;
            margin-bottom: 40px;
        }
        @media only screen and (max-width: 767px) {
            margin-bottom: 25px;
            padding: 20px;
        }
        .d-flex {
            height: 80%;
        }
        p {
            margin-bottom: 33px;
        }
        .text-block {
            font-size: 34px;
            max-width: 90%;
            margin: auto;
            @media only screen and (max-width: 1024px) {
                font-size: 28px;
                max-width: unset;
                margin: unset;
            }
            @media only screen and (max-width: 767px) {
                font-size: 24px;
            }
        }

        .button-container {
            text-align: center;
            margin-top: 30px;
        }
        .pink-button {
            width: unset;
            padding: 0 30px;
        }
        .form_accordian_container_main {
            padding: unset;
        }
    }
    .landing-content {
        padding: unset;
        margin-bottom: 40px;
        @media only screen and (max-width: 1024px) {
            margin-bottom: 60px;
        }
        @media only screen and (max-width: 767px) {
            margin-bottom: 20px;
        }
        .h-80 {
            height: 80%;
        }
    }
}
.orange-button {
    width: 300px;
    height: 55px;
    background-color: #eb8b2d;
    color:#285780;
    border-radius: 10px;
    font-family: Montserrat-Bold;
    font-size: 28px;
    cursor: pointer;
    border: none;
    outline: none;
}
 .pink-title {
    font-family: Montserrat-Bold;
    font-size: 38px;
    color: #dd3978;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 40px;
    @media only screen and (max-width: 1024px) {
        font-size: 30px;
        margin-bottom: unset;
    }
    @media only screen and (max-width: 767px) {
        font-size: 24px;
    }
 }

 .text-center {
     text-align: center;
 }