.new-landing {
  max-width: 1440px;
  margin: auto;
  background-color: #fafafa;
  b {
    font-family: Montserrat-Bold;
  }
  .pink {
    color: #dd3978;
  }
  .bg-pink {
    background-color: #fbe2e5;
  }
  .bg-yellow {
    background-color: #f9e2be;
  }
  .bg-lightblue {
    background-color: #dfe7e8;
  }
  .text-block {
    font-family: Montserrat-Regular;
    font-size: 32px;
    line-height: 1.4;
    @media only screen and (max-width: 1024px) {
      font-size: 26px;
      line-height: 1.2;
    }
    @media only screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 1.2;
    }
    color: #285780;
    text-align: center;
  }
  .text-title {
    font-family: Montserrat-Bold;
    font-size: 36px;
    line-height: 1;
    @media only screen and (max-width: 1024px) {
      font-size: 28px;
      line-height: 1.2;
    }
    @media only screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 1.2;
      max-width: 85%;
      margin: auto;
      margin-bottom: 15px;
      margin-top: 15px;
    }
  }
  .subtext {
    font-family: Montserrat-Regular;
    color: #1a1919;
    font-size: 24px;
    line-height: 1.4;
    @media only screen and (max-width: 1024px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 767px) {
      margin-bottom: 18px;
    }
  }
  &-block1 {
    padding: 60px 80px;
    @media only screen and (max-width: 1024px) {
      padding: 40px 60px;
    }
    @media only screen and (max-width: 767px) {
      padding: 30px 20px;
    }
  }
  &-block2 {
    padding: 60px 80px;
    @media only screen and (max-width: 1024px) {
      padding: 40px 60px;
    }
    @media only screen and (max-width: 767px) {
      padding: 30px 20px;
    }
    background-color: #f9e2be;
    text-align: center;
    button {
      margin-top: 30px;
    }
  }
  .pink-button {
    width: 300px;
    height: 55px;
    background-color: #dd3978;
    border-radius: 10px;
    font-family: Montserrat-Bold;
    font-size: 28px;
    color: #f4d07e;
    cursor: pointer;
    border: none;
    outline: none;
    @media only screen and (max-width: 1024px) {
      height: 36px;
      width: 200px;
      letter-spacing: 1px;
      font-size: 18px;
    }
  }
  .yellow-button {
    width: 300px;
    height: 55px;
    background-color: #ea8b32;
    color: #ffffff;
    border-radius: 10px;
    font-family: Montserrat-Bold;
    font-size: 28px;
    cursor: pointer;
    border: none;
    outline: none;
    @media only screen and (max-width: 1024px) {
      height: 36px;
      width: 170px;
      font-family: Montserrat-SemiBold;
      font-size: 18px;
      border-radius: 8px;
      padding: 10px;
      margin-top: 30px;
    }

    @media only screen and (max-width: 767px) {
      width: 200px;
      letter-spacing: 1px;
      font-size: 18px;
    }
  }
  &-search {
    padding: 60px 80px;
    display: grid;
    @media only screen and (max-width: 1024px) {
      padding: 40px 60px;
    }
    @media only screen and (max-width: 768px) {
      padding: 40px 30px;
    }
    @media only screen and (max-width: 767px) {
      padding: 30px 20px;
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    grid-template-columns: repeat(13, 1fr);
    align-items: center;
    img {
      width: 100%;
    }
    .grid1 {
      grid-column-start: 1;
      grid-column-end: 6;
      @media only screen and (max-width: 1024px) {
        grid-column-end: 7;
      }
      @media only screen and (max-width: 767px) {
      }
    }
    .grid2 {
      grid-column-start: 8;
      grid-column-end: 13;
      @media only screen and (max-width: 1024px) {
        grid-column-end: 14;
      }
      @media only screen and (max-width: 767px) {
      }
    }
    .search-container {
      display: flex;
      justify-content: space-between;
      .landing_page_city_button {
        background-color: #dd3978;
        border: none;
        color: #ffffff;
        font-family: Montserrat-SemiBold;
        // border-radius: 8px;
        width: 176px;
        font-size: 20px;
        border-radius: 8px;
        margin-left: 15px;
        z-index: 2;
        @media only screen and (max-width: 768px) {
          width: 110px;
          font-size: 16px;
        }
        @media only screen and (max-width: 767px) {
        }
        padding: unset;
        outline: none;
        cursor: pointer;
        &:disabled {
          background: #818181 none !important;
          cursor: text;
        }
      }
    }
    .ui.selection.dropdown {
      font-family: Montserrat-Regular;
      min-height: 47px !important;
      // border: 2px solid #dd3978!important;
      border-color: #dd3978 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: unset !important;
      margin-left: unset;
      margin-right: 20px !important;
      input {
        font-size: 18px;
      }
      .message {
        font-size: 16px;
      }
    }
    .ui.search.dropdown > .text {
      padding-left: 20px;
      font-size: 16px;
      @media only screen and (max-width: 768px) {
        padding-left: 8px;
        font-size: 14px;
      }
      @media only screen and (max-width: 767px) {
      }
    }
    .ui.selection.dropdown > .dropdown.icon {
      top: unset !important;
      font-size: 16px;
    }
    .ui.selection.active.dropdown .menu {
      border-color: #dd3978;
      // border-width: 2px!important;
      border-bottom-width: 0 !important;
    }
  }
  .d-flex {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 60%;
    @media only screen and (max-width: 1024px) {
      height: 80%;
    }
    @media only screen and (max-width: 767px) {
    }
  }
  .reverse {
    @media only screen and (max-width: 767px) {
      flex-direction: column-reverse !important;
    }
  }
  .landing-content {
    padding: 60px 80px;
    @media only screen and (max-width: 1024px) {
      padding: 40px 60px;
    }
    @media only screen and (max-width: 767px) {
      padding: 30px 20px;
      display: flex;
      flex-direction: column;
    }
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    align-items: center;
    img {
      width: 100%;
    }
    .content1 {
      grid-column-start: 1;
      grid-column-end: 7;
      img {
        width: 70%;
      }
      @media only screen and (max-width: 767px) {
        text-align: center;
        align-items: center;
      }
    }
    .content2 {
      grid-column-start: 8;
      grid-column-end: 14;
      img {
        width: 90%;
      }
      @media only screen and (max-width: 767px) {
        text-align: center;
        align-items: center;
      }
    }
  }

  .app-download {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: 150px;
      height: 45px;
      background-color: #dd3978;
      border-radius: 8px;
      font-family: Montserrat-SemiBold;
      font-size: 16px;
      color: #f4d07e;
      cursor: pointer;
      border: none;
      outline: none;
      margin-left: 10px;
      margin-right: 10px;
    }
    img {
      width: unset;
    }
  }

  .d-mobile {
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }
  .d-web {
    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }
  .d-tablet {
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
}
