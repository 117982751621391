.registerButton {
  border-radius: 8px !important;
  width: 100% !important;
  height: 44px!important;
  margin-top: 10px!important;
  background: linear-gradient(
                  to top,
                  #ab2814 1%,
                  #ec6338 15%,
                  #f39149
  ) !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.4px;
  color: #ffffff !important;
  box-shadow: none !important;
  @media only screen and (max-width: 768px)  {
    margin-top: 20px !important;

  }
  @media only screen and (max-width: 360px)  {
    padding: 10px !important;
  }
  @media only screen and (max-width: 375px)  {
    padding: 10px !important;
  }
}

.register-button {
  display: block !important;
  margin: 0 auto !important;
  width: 100%;
  height: 44px;
  border-radius: 8px !important;
  color: #ffffff !important;
  background: linear-gradient(
                  to top,
                  #ab2814 1%,
                  #ec6338 15%,
                  #f39149
  ) !important;
}

.register-button:hover {
  background: linear-gradient(
                  to bottom,
                  #ab2814 1%,
                  #ec6338 15%,
                  #f39149
  ) !important;
}

.register-button-merchant {
  width: 100%;
  height: 44px;
  margin: 7px 30px 5px 0;
  padding: 11px 40px 11px 42px;
  border-radius: 8px;
  border: solid 1px #e96036;
  background-color: #ffffff;

  p {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.16px;
    text-align: center;
    color: #e96036;
  }
  @media only screen and (max-width: 360px)  {
    padding: 10px !important;
  }
}

.login-modal-mob {
  @media only screen and (max-width: 360px)  {
    display: flex;
    flex-direction: column;
  }
  @media only screen and (max-width: 375px)  {
    display: flex;
    flex-direction: column;
  }
}

.content-new {
  border-left: 1px solid #d8d8d8!important;
  display: flex!important;
  flex-direction: column!important;
  @media only screen and (max-width: 360px)  {
    border-left: none!important;
  }
  @media only screen and (max-width: 375px)  {
    border-left: none!important;
  }
  @media only screen and (max-width: 768px)  {
   // margin-top: 5px;
    //padding: 2px;
  }
  .content-mob {
    padding: 10px;
    display: flex;
    margin-top: 21px;
    @media only screen and (max-width: 768px)  {
     margin-top: 0;
      padding: 5px;
    }
    @media only screen and (max-width: 360px)  {
      padding: 12px!important;
    }
    @media only screen and (max-width: 375px)  {
      padding: 0;
    }
  }
}

.register-text {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 14px;
  letter-spacing: 0.14px;
  color: #333333;
  padding: 10px;
  text-align: left;
  @media only screen and (max-width: 360px)  {
    padding: 0px;
  }
  @media only screen and (max-width: 375px)  {
    padding: 0;
  }
}