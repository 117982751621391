.mall_navbar_container {
  // background-image: url('../../assets/images/mall-header.png');
  background-size: cover;
  background-position: 70% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 26%;
  position: relative;
  .home_navbar_continer {
    position: initial;
  }
}
.mall_detail_content {
  max-width: 100vw;
  overflow: hidden;
  //display: grid;
  //grid-template-columns: repeat(18, 1fr);
  //grid-template-rows: auto auto auto auto auto;
  // padding-left: 49px;
  // padding-right: 30px;
}
.mall_detail_content_description {
  // padding: 20px 130px;
  // @media (min-width: 768px) and (max-width: 1024px) {
  //   padding: 20px 56px;
  // }
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 19;
  padding-left: 49px;
  padding-right: 30px;
  padding-top: 20px;
  .mall_name_container {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 400px) {
      padding: 25px !important;
    }
  }
  .mall_heart_image {
    width: 23px;
    height: 21px;
    margin-top: 15px;
  }
}
.mall_detail_content_description_name {
  //   width: 848px;
  height: 46px;
  font-family: "Open Sans", sans-serif;
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.36px;
  color: #333333;
  margin-top: 20px !important;
  margin-bottom: 20px;
}
.mall_detail_content_description_content {
  //   max-width: 1319px;
  //   max-height: 78px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.1px;
  color: #333333;
}
.mall_view_go_to_site {
  color: inherit;
  border: none;
  padding: 0;
  width: 328px;
  @media only screen and (max-width: 1024px) {
    width: 200px;
  }

  height: 50px;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
  background: linear-gradient(
    to top,
    #ab2814 1%,
    #ec6338 15%,
    #f39149
  ) !important;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: center;
  color: #ffffff;
  margin: 20px 0;
}
.right_button_mall {
  // padding-right: 40px;
  //position: absolute;
  right: 35px;
  top: 10vw;
  height: max-content;
  @media only screen and (max-width: 1024px) {
    // top: 66px;
    right: 40px;
  }
}
.left_button_mall {
  // padding-left: 65px;
  position: absolute;
  left: 50px !important;
  // left: calc(100vw - 120px) !important;
  // top: 165px;
  top: 10vw;
  height: max-content;
  // @media only screen and (max-width: 1024px) {
  //   top: 130px;
  //   left: calc(100vw - 80px) !important;
  // }
}

// .aoTUt {
//   right: 0px !important;
//   top: -55px !important;
// }

// .dHQcjo {
//   padding-left: 65px;
//   position: absolute;
//   //left: 0 !important;
//   left: calc(100vw - 120px) !important;
//   top: 0px;
//   height: max-content;
// }

.geRExE {
  @media only screen and (max-width: 1024px) {
    margin-left: 15px !important;
  }
}
.mall_cards_carousal_container {
  width: 100%;
  background-image: linear-gradient(73deg, #3b58e0 0%, #6bb5fc 100%);
  margin: 20px 0;
}
.shop_gallary {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 19;
}

.mall_cards_carousal_container_heading {
  width: 267px;
  height: 30px;
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.94px;
  color: #ffffff;
  margin-left: 80px;
  padding-top: 28px !important;
}
.main_wrapper_cards_mall {
  padding-left: 50px !important;
  padding-right: 35px;
  // padding-right: 135px;
}
.shop_offers_container_title {
  margin-left: -30px;
}
.malls_shops {
  grid-row-start: 4;
  grid-row-end: 5;
  grid-column-start: 1;
  grid-column-end: 19;
  padding-left: 40px;
  // padding-right: 30px;
  @media (min-width: 1001px) and (max-width: 1024px) {
    padding-left: 61px;
  }
}
// .malls_shops_container {
//   justify-content: space-between;
// }
.mall_images_carousel {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(11, 1fr);
  //   grid-template-columns: repeat(auto-fit, minmax(152px, 1fr));
  grid-auto-rows: 200px;
  grid-auto-flow: dense;
  margin-bottom: 50px;
  padding-left: 130px;
  //   grid-auto-flow: column;
  .horizontal {
    grid-column: span 3;
    img {
      border-radius: 8px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .vertical {
    grid-row: span 2;
    grid-column: span 2;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
  .small {
    grid-row: span 1;
    grid-column: span 1;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
  .big {
    grid-row: span 1;
    grid-column: span 2;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
  .medium {
    grid-row: span 1;
    grid-column: span 4;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
}
.mall_mapview_card {
  // width: 1000px;
  // height: 395px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 25px 0 rgba(139, 192, 221, 0.5);
  grid-column-start: 3;
  grid-column-end: 17;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media only screen and (min-width: 1200px) {
    grid-column-start: 5;
    grid-column-end: 15;
    min-height: 300px;
  }

  // margin: 0 auto;
  // @media only screen and (max-width: 1024px) {
  //   // width: 725px;
  //   width: 500px;
  // }
  // @media only screen and (max-width: 800px) {
  //   width: 600px;
  //   margin-left: 30px;
  // }
}
.malll_mapview_card_map {
  //   width: 400px;
  flex: 0 0 60%;
  // height: 395px;
  .leaflet-container {
    width: 100%;
    height: 100%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
.mall_mapview_card_details {
  flex: 0 0 40%;
  padding: 30px;
}
.mall_mapview_card_details_heading {
  font-family: "Open Sans", sans-serif;
  height: max-content;
  width: max-content;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.03px;
  color: #333333;
  margin: 10px 0;
}
.mall_mapview_card_details_sub_heading {
  width: max-content;
  height: max-content;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.67px;
  color: #333333;
  margin: 30px 0;
}
.mall_timing_details_container {
  display: flex;
  justify-content: space-between;
  margin: 13px 0;
}
.mall_timing_details_name {
  width: 100%;
  height: max-content;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #333333;
  text-transform: uppercase;
}
.mall_timing_details_value {
  width: max-content;
  height: max-content;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: center;
  color: #333333;
  margin-left: 15px;
}
.mall_timing_address_container {
  display: flex;
  margin: 20px 0;
}
.mall_centre_address {
  display: inline-block;
  width: max-content;
  height: max-content;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.09px;
  color: #616161;
  margin-left: 13px;
}
.mall_mapview_container {
  margin-top: 25px;
  width: 100%;
  // height: 497px;
  height: max-content;
  background-image: linear-gradient(71deg, #3b58e0 0%, #6bb5fc 100%);
  grid-row-start: 5;
  grid-row-end: 6;
  grid-column-start: 1;
  grid-column-end: 19;
  padding: 50px 0;
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  // @media only screen and (max-width: 768px) {
  //   padding-right: 200px;
  // }
}
// .mall_mapview_padding {
//   padding-right: 125px !important;
// }
.shoppping_promo_card_mall {
  margin: 25px 0;
  @media only screen and (max-width: 768px) {
    margin: 18px 0;
  }
}

.corusal_iamge {
  border: 1px solid;
  grid-area: span 1 / span 1;
}
.mall_loader {
  margin: 100px 50% !important;
}
.shop_default_address {
  margin-left: 20px;
}
.mall_heart_button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.shopping_center_div_for_mall {
  padding-left: 0;
  padding-right: 30px;
  margin-top: 20px;
  margin-bottom: 50px;
}
.mall_card_item_wrapper {
  width: 20vw;
}
// .shoppping_centre_card {
//   flex: 0 1 30%;

//   .card_heart_container {
//     width: 34px;
//     height: 34px;
//     border-radius: 8px;
//     box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
//     background-color: #ffffff;
//     position: absolute;
//     right: 10px;
//     top: 10px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     button {
//       margin: auto;
//     }
//     @media only screen and (max-width: 768px) {
//       display: none;
//     }
//   }
//   @media only screen and (max-width: 1024px) {
//     flex-basis: 50%;
//   }
//   @media only screen and (max-width: 768px) {
//     flex-basis: 100%;
//     height: 150px;
//   }
//   .shoppping_centre_card_image img {
//     width: 100%;
//     @media only screen and (max-width: 1024px) {
//       height: 144px;
//     }
//     @media only screen and (min-width: 1100px) {
//       height: 230px;
//     }
//     height: 230px;
//     border-top-left-radius: 8px;
//     border-top-right-radius: 8px;
//     @media only screen and (max-width: 768px) {
//       width: 134px;
//       height: 150px;
//       border-bottom-left-radius: 8px;
//       border-top-right-radius: 0px;
//     }
//   }
//   @media only screen and (max-width: 375px) {
//     flex: 0 0 100%;
//   }

//  }
