.listing_container {
  display: flex;
  flex-wrap: wrap;
  // padding-left: 33px;
  // padding-right: 12px;
  // margin-bottom: 250px;
  grid-row-start: 2;
  grid-row-end: 9;
  grid-column-start: 1;
  grid-column-end: 19;
  display: grid;
  grid-gap: 30px;
  // grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  @media only screen and (max-width: 1400px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
  // @media only screen and (max-width: 1024px) {
  //   grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
  // }
  // @media only screen and (max-width: 830px) {
  //   grid-template-columns: repeat(auto-fill, minmax(195px, 1fr));
  //   grid-gap: 20px;
  // }
  // @media (min-width: 1025px) and (max-width: 1300px) {
  //   grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  // }
  margin-top: 110px;
  margin-bottom: 60px;

  @media only screen and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
  // justify-content: space-between;
  // .shoppping_centre_card {
  //   cursor: pointer;
  //   position: relative;
  //   max-width: 327px;
  //   background: #ffffff;
  //   border-radius: 8px;
  //   box-shadow: 0 0 25px 0 rgba(139, 192, 221, 0.5);
  //   background-color: #ffffff;
  //   padding-bottom: 20px;
  //   margin: 20px;
  //   flex: 1 1 327px;
  //   max-width: 30%;

  //   &-image img {
  //     width: 100%;
  //     @media only screen and (max-width: 1024px) {
  //       height: 144px;
  //     }
  //     @media only screen and (min-width: 1100px) {
  //       height: 230px;
  //     }
  //     height: 230px;
  //     // height: 70%;
  //     border-top-left-radius: 8px;
  //     border-top-right-radius: 8px;
  //     @media only screen and (max-width: 768px) {
  //       width: 134px;
  //       height: 150px;
  //       border-bottom-left-radius: 8px;
  //       border-top-right-radius: 0px;
  //     }
  //   }
  //   .card_heart_container {
  //     width: 34px;
  //     height: 34px;
  //     border-radius: 8px;
  //     box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
  //     background-color: #ffffff;
  //     position: absolute;
  //     right: 10px;
  //     top: 10px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     //text-align: center;
  //     //padding-top: 5px;
  //     button {
  //       margin: auto;
  //     }
  //     @media only screen and (max-width: 768px) {
  //       display: none;
  //     }
  //   }
  //   @media only screen and (min-width: 1600px) {
  //     max-width: 23%;
  //   }
  //   @media only screen and (max-width: 1300px) {
  //     max-width: 28%;
  //   }
  //   > img {
  //     width: 100%;
  //     @media only screen and (min-width: 1025px) {
  //       min-height: 213px;
  //       height: 230px;
  //       border-top-left-radius: 8px;
  //       border-top-right-radius: 8px;
  //     }
  //   }
  //   @media only screen and (max-width: 1300px) {
  //     flex: 1 1 250px;
  //     width: 250px;
  //   }
  //   @media only screen and (max-width: 1200px) {
  //     flex: 1 1 240px;
  //     width: 240px;
  //   }
  //   @media only screen and (max-width: 1000px) {
  //     flex: 1 1 180px;
  //     width: 180px;
  //   }
  //   @media only screen and (max-width: 900px) {
  //     flex: 1 1 150px;
  //     width: 150px;
  //     // max-width: 20%;
  //   }
  //   @media only screen and (max-width: 1024px) {
  //     padding-bottom: 40px;
  //     padding-right: 5px;
  //   }
  // }

  // .shopping_centre_name {
  //   // width: 327px;
  //   width: 100%;
  //   height: 54px;
  //   font-family: "Open Sans";
  //   font-weight: bold;
  //   font-size: 18px;
  //   font-stretch: normal;
  //   font-style: normal;
  //   line-height: 1.17;
  //   letter-spacing: 0.67px;
  //   color: #333333;
  //   padding: 10px;
  //   @media only screen and (max-width: 1000px) {
  //     font-size: 16px;
  //   }
  //   @media only screen and (max-width: 1024px) {
  //     font-size: 14px;
  //     height: 30px;
  //   }
  // }

  // .shopping_center_address_container {
  //   display: flex;
  //   padding: 0 50px 0 10px;

  //   @media only screen and (max-width: 768px) {
  //     padding: 0 0px 0 10px;
  //   }
  // }

  // .shopping_centre_address {
  //   font-family: "Open Sans", sans-serif;
  //   font-size: 14px;
  //   font-weight: normal;
  //   font-stretch: normal;
  //   font-style: normal;
  //   line-height: normal;
  //   letter-spacing: 0.09px;
  //   color: #616161;
  //   margin-left: 10px;
  //   @media only screen and (max-width: 1000px) {
  //     font-size: 12px;
  //   }
  // }
}

.listing_container::before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(9 / 16 * 100%);
  position: absolute;
  left: -20px;
}
.list_page_loader {
  grid-column-start: 9;
  grid-row-start: 4;
}
.search_filter_container {
  position: relative;
  // width: calc(100vw - 270px);
  height: 60px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  align-items: center;
  // padding: 0 15px;
  // margin: 20px;
  // margin-left: 50px;
  display: flex;
  justify-content: space-between;
  grid-row-start: 2;
  grid-row-end: 9;
  grid-column-start: 1;
  grid-column-end: 19;
  padding-left: 13px;
  // @media only screen and (max-width: 768px) {
  //   margin: 20px 20px 20px 20px;
  //   width: calc(100vw - 100px);
  // }
}

.map_view_text_container {
  display: inline-block;
  // margin-right: 10px;
}
.search_filter_text {
  margin-left: 10px;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
.search_drop {
  display: inline-block;
  margin-right: 10px;
}
.map_view_text {
  margin-left: 10px;
  display: inline-block;
  height: 21px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.16px;
  color: #333333;
}
.search_filter {
  margin-left: 10px;
  display: inline-block;
  height: 21px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.16px;
  color: #333333;
}
.offers_active_text {
  // width: 300px;
  height: 14px;
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  color: #939090;
}
.list_page_headings {
  // margin: 20px 0 20px 53px;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 4;
  // margin-top: 119px;
  // @media only screen and (max-width: 800px) {
  //   margin: 0 0 20px 20px;
  // }
}
.map_view_button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
}
.listing_filters_popup {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  // width: 1400px;
  // width: calc(100vw - 120px);
  // width: calc(100vw - 210px);
  height: max-content;
  // min-height: 309px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  // padding: 0 10px;
  padding-bottom: 20px;
}
.listing_filters_popup_search_filter {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  // width: calc(100vw - 150px);
  // width: calc(100vw - 250px);
  height: 60px;
  align-items: center;
  // padding: 0 5px;
  // margin: 0 10px;
  padding-left: 13px;
}
.categories_item {
  display: inline-block;
  width: max-content;
  height: 32px;
  border-radius: 16px;
  border: solid 1px #d0d0d0;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.51px;
  color: #939090;
  padding: 10px;
  padding-top: 5px;
  margin: 10px;
}
.categories_text {
  width: 263px;
  height: 16px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.51px;
  color: #333333;
  margin: 10px !important;
  text-transform: uppercase;
}
.categories_item_selected {
  background-image: linear-gradient(79deg, #3b58e0 0%, #6bb5fc 100%);
  letter-spacing: 0.51px;
  color: #ffffff;
}
.close_selected_category {
  margin-left: 15px;
  width: 9px;
  height: 9px;
  color: #ffffff;
}
.filter_close {
  width: 12px;
  transform: rotate(45deg);
  border-radius: 1.2px;
  margin-left: 5px;
}
.filter_toggke {
  width: 10px;
  height: 10px;
}
// .filter_plus {
//   margin-left: 5px;
// }
// .filter_items_columns {
//   flex: 0 0 430px;
//   @media only screen and (max-width: 1200px) {
//     flex: 0 0 450px;
//   }
// }

.search_filter_modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.landing_page_root {
  // padding: 100px 85px;
  // min-height: calc(100vh - 56px);
  margin-top: 150px;
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  grid-template-rows: 80px 200px auto 400px;
  padding-left: 43px;
  padding-right: 35px;
  @media only screen and (max-width: 1024px) {
    padding-left: 60px;
    padding-right: 30px;
  }
  @media only screen and (max-width: 1000px) {
    padding-left: 40px;
    padding-right: 30px;
  }

  // @media only screen and (max-width: 768px) {
  //   padding: 100px 27px 110px 40px;
  // }
}
