// .landing_page_root_fav {
//   // padding: 100px 100px 100px 120px;
//   @media only screen and (max-width: 768px) {
//     padding-left: 45px;
//   }
// }
// .search_filter_container_fav {

// }

// .list_page_headings_fav {
//   margin-left: 20px !important;
// }
.listing_container_fav {
  padding-left: 0px !important;
}
// .listing_filters_popup_fav {
//   left: 31px !important;
//   width: calc(100vw - 243px) !important;
//   @media only screen and (max-width: 768px) {
//     width: calc(100vw - 112px) !important;
//   }
// }

// .listing_filters_popup_search_filter_fav {
//   width: calc(100vw - 270px) !important;
//   padding: 0 !important;
//   @media only screen and (max-width: 768px) {
//     width: calc(100vw - 140px) !important;
//   }
// }

// .search_fav_filter_modal {
//   width: calc(100vw - 250px) !important;
//   top: 193px !important;
//   left: 105px !important;
//   @media only screen and (max-width: 768px) {
//     width: calc(100vw - 112px) !important;
//     left: 30px !important;
//   }
// }
