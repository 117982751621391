.fidelity_page {
  min-height: calc(100vh - 56px);
}
.fidelity_header {
  background-image: url("../../assets/images/fidelity-header.png");

  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 24.76%; /* (img-height / img-width * container-width) */
  /* (853 / 1280 * 100) */
  position: relative;

  .fidelity_header_text {
    position: absolute;
    bottom: 50px;
    left: 40px;
    // left: 7%;
    // width: 646px;
    height: 47px;
    font-family: "Open Sans", sans-serif;
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.34;
    letter-spacing: 0.36px;
    color: #ffffff;
    @media only screen and (max-width: 1000px) {
      font-size: 20px;
      bottom: 30px;
    }
    @media only screen and (max-width: 900px) {
      font-size: 18px;
      bottom: 10px;
    }
    @media only screen and (max-width: 800px) {
      bottom: 5px;
      font-size: 16px;
    }
  }
}
.your_card_column {
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 2;
  border-radius: 8px;
  box-shadow: 0 0 25px 0 rgba(139, 192, 221, 0.5);
  background-color: #ffffff;
  // max-height: 609px;
  // @media only screen and (max-width: 1400px) {
  //   width: 420px;
  // }
  // @media only screen and (max-width: 1000px) {
  //   width: 253px;
  //   // min-height: 535px;
  // }
  height: max-content;
}
.your_card_image_container {
  width: 100%;
  height: max-content;
  // height: 315px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-image: linear-gradient(58deg, #3b58e0 0%, #6bb5fc);
  padding: 20px;
  // padding: 20px 49px 0px 52px;
  position: relative;
  .your_card_text {
    width: 267px;
    height: 30px;
    font-family: "Open Sans", sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.94px;
    color: #ffffff;
  }
}
.transaction_header {
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .header_data_flex_size {
    flex: 0 0 40px;
  }
  .header_price_flex_size {
    flex: 0 0 75px;
  }
  .header_points_flex_size {
    flex: 0 0 40px;
  }
}
.transaction_date_header {
  width: 38px;
  height: 14px;
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.84px;
  text-align: right;
  color: #333333;
}
.transaction_data {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background-color: #f5f5f5;
  .transaction_data_date {
    width: 65px;
    height: 17px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    color: #4c4c4c;
  }
  .transaction_data_price {
    width: auto;
    height: 17px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    text-align: center;
    color: #333333;
  }
  .transaction_data_points {
    width: auto;
    height: 17px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    text-align: right;
    color: #333333;
  }
}
.last_five_transactions {
  width: 100%;
  height: 16px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #424242;
  padding: 20px 10px;
}
.other_cards_section {
  // flex: 1 1 77%;
  grid-column-start: 2;
  grid-column-end: 5;
}
.most_used_section_container {
  padding: 30px 0;
  padding-right: 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto auto;
}
.most_used_heading {
  // width: 267px;
  width: 100%;
  // height: 30px;
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.94px;
  color: #333333;
  // margin-left: 20px;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 6;
}
.most_used_section {
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 6;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(208px, 1fr));
  grid-gap: 20px;
  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 20px;
  }
}
.other_card_shape {
  // margin: 10px;
  // max-width: 208px;
  height: 130px;
  border-radius: 8px;
  box-shadow: 0 0 25px 0 rgba(139, 192, 221, 0.5);
  background-color: #8d1731;
  position: relative;
  padding: 15px;
  width: 100%;
  // @media (max-width: 1400px) and (min-width: 1025px) {
  //   min-width: 208px !important;
  // }

  // @media only screen and (max-width: 768px) {
  //   max-width: 150px;
  //   min-height: 100px;
  // }

  // flex: 1;
  // min-width: 208px;
  // max-width: 210px;
  // min-width: 20%;
  // @media (max-width: 1400px) {
  //   min-width: 33.33%;
  // }
  // @media only screen and (max-width: 1400px) {
  //   width: 188px;
  // }
  // @media only screen and (max-width: 1000px) {
  //   width: 180px;
  // }
  :hover {
    cursor: pointer;
  }
}

.other_card_text {
  // width: 178px;
  height: 65px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #ffffff;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  // @media only screen and (max-width: 1400px) {
  //   font-size: 15px;
  // }
  @media only screen and (max-width: 1000px) {
    width: 100%;
    // height: 56px;
    // font-size: 11px;
    letter-spacing: 0.7px;
  }
}
.points_on_card {
  position: absolute;
  right: 0;
  bottom: 0;
  // width: 75px;
  height: 37px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  box-shadow: -1px -1px 6px -1px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  padding: 7px 14px;
  .point_number {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.52px;
    color: #333333;
  }
  .pt_text {
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 1.08px;
  }
}
.fidelity_content_section {
  // display: flex;
  // justify-content: space-between;
  // padding: 50px 25px;
  // padding: 50px 105px 50px 137px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  padding-left: 40px;
  padding-right: 30px;
  margin: 20px 0;
  // @media only screen and (max-width: 1000px) {
  //   padding: 50px 30px 50px 46px;
  // }
}
.your_card_image {
  width: 100%;
}
.fidelity_user_card {
  // background-image: url('../../assets/images/Fidelity_card.svg');
  // background-image: url("../../assets/images/new-fidelity-card.png");
  // background-size: contain;
  // background-repeat: no-repeat;
  width: 100%;
  height: max-content;
  position: relative;
  // padding-top: 150%; /* (img-height / img-width * container-width) */
  /* (853 / 1280 * 100) */

  .fidelity_user_card_name {
    position: absolute;
    top: 35%;
    left: 15%;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    // font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    color: #ffffff;
    padding-right: 50px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // @media only screen and (max-width: 768px) {
    //   font-size: 10px;
    //   top: 55px;
    //   left: 25px;
    // }
  }
  .fidelity_user_card_id_name {
    position: absolute;
    top: 120px;
    left: 42px;
    color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    color: #ffffff;
    @media only screen and (max-width: 768px) {
      top: 70px;
      left: 25px;
    }
  }
  .fidelity_user_card_id_value {
    position: absolute;
    top: 133px;
    left: 42px;
    color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    color: #ffffff;
    @media only screen and (max-width: 768px) {
      top: 85px;
      left: 25px;
    }
  }
  .fidelity_user_card_point_name {
    // position: absolute;
    // top: 130px;
    // left: 42px;
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    color: #ffffff;
    // @media only screen and (max-width: 768px) {
    //   top: 97px !important;
    //   left: 25px;
    //   font-size: 8px;
    // }
    // @media only screen and (max-width: 1400px) {
    //   top: 150px;
    // }
  }
  .fidelity_user_card_point_value {
    // position: absolute;
    // top: 150px;
    // left: 42px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    color: #ffffff;
    // @media only screen and (max-width: 768px) {
    //   top: 108px !important;
    //   left: 25px;
    //   font-size: 11px;
    // }
    // @media only screen and (max-width: 1400px) {
    //   top: 165px;
    // }
  }
  .fidelity_user_card_cash_name {
    // position: absolute;
    // top: 130px;
    // right: 150px;
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    color: #ffffff;
    // @media only screen and (max-width: 768px) {
    //   top: 97px !important;
    //   font-size: 8px;
    //   right: 86px;
    // }
    // @media only screen and (max-width: 1400px) {
    //   top: 150px;
    // }
  }
  .ponit_key_value_container {
    display: flex;
    justify-content: start;
    flex-direction: column;
  }
  .cash_key_value_container {
    display: flex;
    justify-content: start;
    flex-direction: column;
    margin-left: 40px;
  }
  .point_cash_main_container {
    display: flex;
    position: absolute;
    top: 60%;
    left: 15%;
  }
  .fidelity_user_card_cash_value {
    // position: absolute;
    // top: 150px;
    // right: 160px;
    color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    color: #ffffff;
    // @media only screen and (max-width: 768px) {
    //   top: 107px !important;
    //   font-size: 12px;
    //   right: 117px;
    // }
    // @media only screen and (max-width: 1400px) {
    //   top: 165px;
    // }
  }
}
.qr_conatainer {
  position: absolute;
  top: 165px;
  right: 87px;
  @media only screen and (max-width: 768px) {
    top: 140px !important;
    right: 55px;
  }
}
.qr_conatainer_bigger_size {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}
.qr_conatainer_smaller_size {
  @media only screen and (min-width: 769px) {
    display: none;
  }
}
