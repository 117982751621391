.landing_form_container {
  grid-column-start: 1;
  grid-column-end: 7;
  @media only screen and (max-width: 1024px) {
      margin-bottom: 30px;
      text-align: center;
  }
}
.landing_form {
  h1 {
    font-family: Montserrat-Bold;
    font-size: 27px;
    margin-bottom: 40px;
  }
  button {
    width: 139px;
    height: 36px;
    border: solid 1.2px #eaa936;
    font-family: Montserrat-SemiBold;
    font-size: 18px;
    color: #285780;
    border-radius: 8px;
    background-color: #ffffff;
    cursor: pointer;
    outline: none;
    background-color: #eaa936;
  }
}
.landingFormTextArea {
  //   height: 150px !important;
  border-radius: 8px !important;
  border: solid 1px #d5d5d5 !important;
  background-color: #ffffff !important;
}
.business_section_mobile {
  @media only screen and (min-width: 500px) {
    display: none;
  }
}

.landing_form_fields {
  width: 100% !important;
  height: 50px;
  input {
    margin-left: unset!important;
    margin-right: unset!important;
  }
}
.writeToInputContainer {
  padding-bottom: 30px;
  position: relative;
}
.ladingFormError {
  position: absolute;
  top: 53px;
  left: 14px;
  color: red;
  font-family: 'Open Sans', sans-serif;
}

.ladingFormErrorTextMessage {
  top: 155px !important;
}
.ladingFormLoader {
  top: -6px !important;
}
